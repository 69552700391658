import _taggedTemplateLiteral from "@babel/runtime/helpers/taggedTemplateLiteral";

function _templateObject() {
  var data = _taggedTemplateLiteral(["\n  display: block;\n  margin-bottom: 0.625rem;\n  font-weight: ", ";\n"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

import { styled } from "@styles";
export var Name = styled.span(_templateObject(), function (props) {
  return props.theme.typography.boldFontWeight;
});