import _slicedToArray from "@babel/runtime/helpers/slicedToArray";
import * as React from "react";
import { maybe } from "../core/utils";

var useClickedOutside = function useClickedOutside() {
  var _React$useState = React.useState(false),
      _React$useState2 = _slicedToArray(_React$useState, 2),
      clickedOutside = _React$useState2[0],
      setClickedOutside = _React$useState2[1];

  var elementRef = React.useRef(null);

  var handleClickOutside = function handleClickOutside(e) {
    if (maybe(function () {
      return elementRef.current && e.target;
    }, null)) {
      setClickedOutside(!elementRef.current.contains(e.target));
    }
  };

  var setElementRef = function setElementRef() {
    return elementRef;
  };

  React.useEffect(function () {
    document.addEventListener("mousedown", handleClickOutside);
    return function () {
      return document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);
  return {
    clickedOutside: clickedOutside,
    setElementRef: setElementRef
  };
};

export default useClickedOutside;