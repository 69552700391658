import _defineProperty from "@babel/runtime/helpers/defineProperty";
import _toConsumableArray from "@babel/runtime/helpers/toConsumableArray";
import { Formik } from "formik";
import React from "react";
import { useIntl, FormattedMessage } from "react-intl";
import { Button, ButtonLink } from "@components/atoms";
import { commonMessages } from "@temp/intl";
import { TextField } from "../TextField";
import * as S from "./styles";
export var PasswordChangeForm = function PasswordChangeForm(_ref) {
  var handleSubmit = _ref.handleSubmit,
      hide = _ref.hide,
      error = _ref.error;
  var intl = useIntl();
  var fieldErrors = {};

  if (error) {
    error.map(function (_ref2) {
      var field = _ref2.field,
          message = _ref2.message;

      if (field && message) {
        fieldErrors[field] = fieldErrors[field] ? [].concat(_toConsumableArray(fieldErrors[field]), [{
          message: message
        }]) : [{
          message: message
        }];
      }
    });
  }

  return React.createElement(React.Fragment, null, React.createElement(Formik, {
    initialValues: {
      confirmPassword: "",
      newPassword: "",
      oldPassword: ""
    },
    onSubmit: function onSubmit(values, _ref3) {
      var setSubmitting = _ref3.setSubmitting;
      handleSubmit({
        newPassword: values.newPassword,
        oldPassword: values.oldPassword
      });
      setSubmitting(false);
    },
    validateOnChange: false,
    validate: function validate(values) {
      var errors = {};

      if (!values.confirmPassword) {
        errors.confirmPassword = intl.formatMessage(_defineProperty({
          defaultMessage: "Required field",
          "id": "@next.components.molecules.AccountTabTiles.PasswordChangeForm.2369435044"
        }, "id", "@next.components.molecules.AccountTabTiles.PasswordChangeForm.2369435044"));
      }

      if (!values.newPassword) {
        errors.newPassword = intl.formatMessage(_defineProperty({
          defaultMessage: "Required field",
          "id": "@next.components.molecules.AccountTabTiles.PasswordChangeForm.2369435044"
        }, "id", "@next.components.molecules.AccountTabTiles.PasswordChangeForm.2369435044"));
      }

      if (!values.oldPassword) {
        errors.oldPassword = intl.formatMessage(_defineProperty({
          defaultMessage: "Required field",
          "id": "@next.components.molecules.AccountTabTiles.PasswordChangeForm.2369435044"
        }, "id", "@next.components.molecules.AccountTabTiles.PasswordChangeForm.2369435044"));
      }

      if (values.confirmPassword !== values.newPassword) {
        errors.confirmPassword = intl.formatMessage(_defineProperty({
          defaultMessage: "Passwords do not match",
          "id": "@next.components.molecules.AccountTabTiles.PasswordChangeForm.4253911811"
        }, "id", "@next.components.molecules.AccountTabTiles.PasswordChangeForm.4253911811"));
        errors.newPassword = intl.formatMessage(_defineProperty({
          defaultMessage: "Passwords do not match",
          "id": "@next.components.molecules.AccountTabTiles.PasswordChangeForm.4253911811"
        }, "id", "@next.components.molecules.AccountTabTiles.PasswordChangeForm.4253911811"));
      }

      return errors;
    }
  }, function (_ref4) {
    var handleChange = _ref4.handleChange,
        handleSubmit = _ref4.handleSubmit,
        handleBlur = _ref4.handleBlur,
        values = _ref4.values,
        errors = _ref4.errors,
        touched = _ref4.touched,
        isSubmitting = _ref4.isSubmitting,
        isValid = _ref4.isValid;
    return React.createElement(S.Form, {
      onSubmit: handleSubmit,
      "data-test": "changePasswordForm"
    }, React.createElement(TextField, {
      name: "oldPassword",
      label: intl.formatMessage(_defineProperty({
        defaultMessage: "Old Password",
        "id": "@next.components.molecules.AccountTabTiles.PasswordChangeForm.2218114941"
      }, "id", "@next.components.molecules.AccountTabTiles.PasswordChangeForm.2218114941")),
      type: "password",
      value: values.oldPassword,
      onBlur: handleBlur,
      onChange: handleChange,
      errors: touched.oldPassword && errors.oldPassword ? [{
        message: errors.oldPassword
      }] : undefined || fieldErrors.oldPassword
    }), React.createElement(TextField, {
      name: "newPassword",
      label: intl.formatMessage(_defineProperty({
        defaultMessage: "New Password",
        "id": "@next.components.molecules.AccountTabTiles.PasswordChangeForm.1254879564"
      }, "id", "@next.components.molecules.AccountTabTiles.PasswordChangeForm.1254879564")),
      type: "password",
      value: values.newPassword,
      onBlur: handleBlur,
      onChange: handleChange,
      errors: touched.newPassword && errors.newPassword ? [{
        message: errors.newPassword
      }] : undefined || fieldErrors.newPassword
    }), React.createElement(TextField, {
      name: "confirmPassword",
      label: intl.formatMessage(_defineProperty({
        defaultMessage: "Confirm Password",
        "id": "@next.components.molecules.AccountTabTiles.PasswordChangeForm.2799926859"
      }, "id", "@next.components.molecules.AccountTabTiles.PasswordChangeForm.2799926859")),
      type: "password",
      value: values.confirmPassword,
      onBlur: handleBlur,
      onChange: handleChange,
      errors: touched.confirmPassword && errors.confirmPassword ? [{
        message: errors.confirmPassword
      }] : undefined || fieldErrors.confirmPassword
    }), React.createElement(S.FormButtons, null, React.createElement(ButtonLink, {
      testingContext: "cancelButton",
      type: "button",
      color: "secondary",
      onClick: hide
    }, React.createElement(FormattedMessage, commonMessages.cancel)), React.createElement(Button, {
      testingContext: "submit",
      type: "submit",
      disabled: isSubmitting || !isValid,
      size: "sm"
    }, React.createElement(FormattedMessage, commonMessages.save))));
  }));
};