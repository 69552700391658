import { createBrowserHistory } from "history";
var history = createBrowserHistory();
history.listen(function (_location, action) {
  if (["PUSH"].includes(action)) {
    window.scroll({
      behavior: "smooth",
      top: 0
    });
  }
});
export { history };