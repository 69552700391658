import React from "react";
import { ButtonLink } from "@components/atoms";
import { CardHeader, OverlayItem } from "@components/molecules";
import { useHandlerWhenClickedOutside } from "@hooks";
import { Overlay } from "..";
import * as S from "./styles";
export var SelectSidebar = function SelectSidebar(_ref) {
  var title = _ref.title,
      _ref$options = _ref.options,
      options = _ref$options === void 0 ? [] : _ref$options,
      _ref$disabledOptions = _ref.disabledOptions,
      disabledOptions = _ref$disabledOptions === void 0 ? [] : _ref$disabledOptions,
      _ref$selectedOptions = _ref.selectedOptions,
      selectedOptions = _ref$selectedOptions === void 0 ? [] : _ref$selectedOptions,
      hide = _ref.hide,
      onSelect = _ref.onSelect,
      show = _ref.show,
      target = _ref.target,
      footerTitle = _ref.footerTitle,
      onClickFooter = _ref.onClickFooter,
      testingContextId = _ref.testingContextId;

  var _useHandlerWhenClicke = useHandlerWhenClickedOutside(function () {
    hide();
  }),
      setElementRef = _useHandlerWhenClicke.setElementRef;

  return React.createElement(Overlay, {
    position: "right",
    duration: 0,
    show: show,
    hide: hide,
    transparent: true,
    target: target,
    testingContext: "attributeSelection",
    testingContextId: testingContextId
  }, React.createElement(S.Wrapper, {
    ref: setElementRef()
  }, React.createElement(CardHeader, {
    divider: true,
    onHide: hide
  }, React.createElement("span", null, title)), React.createElement(S.Content, null, options.map(function (option) {
    var isSelected = selectedOptions.some(function (value) {
      return value === option.value;
    });
    var isDisabled = disabledOptions.some(function (value) {
      return value === option.value;
    });
    return React.createElement(S.Option, {
      key: option.value,
      disabled: isDisabled
    }, React.createElement(OverlayItem, {
      testingContextId: option.value,
      selected: isSelected,
      disabled: isDisabled,
      onClick: function onClick() {
        return onSelect(option.value);
      }
    }, option.label));
  })), footerTitle && React.createElement(S.Footer, {
    onClick: onClickFooter
  }, React.createElement(ButtonLink, {
    testingContext: "footerActionButton",
    color: "secondary"
  }, footerTitle))));
};