import _taggedTemplateLiteral from "@babel/runtime/helpers/taggedTemplateLiteral";

function _templateObject3() {
  var data = _taggedTemplateLiteral(["\n  cursor: pointer;\n  padding-bottom: 1.5rem;\n  color: ", ";\n"]);

  _templateObject3 = function _templateObject3() {
    return data;
  };

  return data;
}

function _templateObject2() {
  var data = _taggedTemplateLiteral(["\n  font-size: ", ";\n  font-weight: ", ";\n  text-transform: \"uppercase\";\n  padding-bottom: 2rem;\n"]);

  _templateObject2 = function _templateObject2() {
    return data;
  };

  return data;
}

function _templateObject() {
  var data = _taggedTemplateLiteral(["\n  background-color: ", ";\n  height: 100%;\n  /* height: auto; */\n  padding-left: 3rem;\n  padding-top: 2.5rem;\n"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

import { styled } from "@styles";
export var Wrapper = styled.div(_templateObject(), function (props) {
  return props.theme.colors.light;
});
export var MenuHeader = styled.div(_templateObject2(), function (props) {
  return props.theme.typography.h3FontSize;
}, function (props) {
  return props.theme.typography.boldFontWeight;
});
export var MenuItem = styled.div(_templateObject3(), function (props) {
  return props.active ? props.theme.colors.activeMenuOption : "";
});