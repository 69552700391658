import React from "react";
import { Icon } from "../Icon";
import * as S from "./styles";
export var Message = function Message(_ref) {
  var title = _ref.title,
      _ref$status = _ref.status,
      status = _ref$status === void 0 ? "neutral" : _ref$status,
      children = _ref.children,
      onClick = _ref.onClick,
      actionText = _ref.actionText;
  var isAction = !!actionText;
  return React.createElement(S.Wrapper, {
    status: status,
    "data-test": "alert"
  }, React.createElement(S.TopWrapper, null, React.createElement(S.Title, null, title), isAction ? !children && React.createElement(S.ActionButton, {
    onClick: onClick
  }, actionText) : React.createElement(S.CloseButton, {
    onClick: onClick
  }, React.createElement(Icon, {
    name: "x",
    size: 15
  }))), children && React.createElement(S.Content, null, children), children && isAction && React.createElement(S.ActionButton, {
    onClick: onClick,
    style: {
      marginTop: "1rem"
    }
  }, actionText));
};