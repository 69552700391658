import _extends from "@babel/runtime/helpers/extends";
import _objectWithoutProperties from "@babel/runtime/helpers/objectWithoutProperties";
import React from "react";
import { Icon } from "../Icon";
import * as S from "./styles";
export var IconButton = function IconButton(_ref) {
  var name = _ref.name,
      color = _ref.color,
      _ref$size = _ref.size,
      size = _ref$size === void 0 ? 36 : _ref$size,
      onClick = _ref.onClick,
      testingContext = _ref.testingContext,
      testingContextId = _ref.testingContextId,
      props = _objectWithoutProperties(_ref, ["name", "color", "size", "onClick", "testingContext", "testingContextId"]);

  return React.createElement(S.Wrapper, _extends({
    "data-test": testingContext,
    "data-test-id": testingContextId,
    onClick: onClick
  }, props), React.createElement(Icon, {
    name: name,
    size: size,
    color: color
  }));
};