import "./scss/index.scss";
import * as React from "react";
import PriceRangeFilter from "../PriceRangeFilter";
import SelectField from "../SelectField";
export var ProductFilters = function ProductFilters(_ref) {
  var attributes = _ref.attributes,
      filters = _ref.filters,
      onAttributeFiltersChange = _ref.onAttributeFiltersChange,
      onPriceChange = _ref.onPriceChange;
  return React.createElement("div", {
    className: "product-filters"
  }, React.createElement("div", {
    className: "container"
  }, React.createElement("div", {
    className: "product-filters__grid"
  }, attributes.map(function (attribute) {
    return React.createElement("div", {
      key: attribute.id,
      className: "product-filters__grid__filter"
    }, React.createElement(SelectField, {
      value: filters.attributes[attribute.slug] ? filters.attributes[attribute.slug].map(function (attributeValueSlug) {
        var attributeValue = attribute.values.find(function (attributeValue) {
          return attributeValue.slug === attributeValueSlug;
        });
        return {
          label: attributeValue.name,
          value: attributeValue.slug
        };
      }) : [],
      placeholder: attribute.name,
      options: attribute.values.map(function (attributeValue) {
        return {
          label: attributeValue.name,
          value: attributeValue.slug
        };
      }),
      isMulti: true,
      onChange: function onChange(values) {
        return onAttributeFiltersChange(attribute.slug, values.map(function (value) {
          return value.value;
        }));
      }
    }));
  }), React.createElement("div", {
    className: "product-filters__grid__filter"
  }, React.createElement(PriceRangeFilter, {
    from: filters.priceGte,
    to: filters.priceLte,
    onChange: onPriceChange
  })))));
};