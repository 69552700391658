import * as React from "react";
import { NetworkStatus } from ".";

var Offline = function Offline(_ref) {
  var children = _ref.children;
  return React.createElement(NetworkStatus, null, function (online) {
    return online ? null : children;
  });
};

export default Offline;