import _taggedTemplateLiteral from "@babel/runtime/helpers/taggedTemplateLiteral";

function _templateObject() {
  var data = _taggedTemplateLiteral(["\n  mutation AccountConfirm($email: String!, $token: String!) {\n    confirmAccount(email: $email, token: $token) {\n      errors {\n        field\n        message\n      }\n    }\n  }\n"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

import gql from "graphql-tag";
import { TypedMutation } from "../../core/mutations";
var accountConfirmMutation = gql(_templateObject());
export var TypedAccountConfirmMutation = TypedMutation(accountConfirmMutation);