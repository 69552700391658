import _taggedTemplateLiteral from "@babel/runtime/helpers/taggedTemplateLiteral";

function _templateObject7() {
  var data = _taggedTemplateLiteral(["\n  border-right: 1px solid ", ";\n  height: 2rem;\n"]);

  _templateObject7 = function _templateObject7() {
    return data;
  };

  return data;
}

function _templateObject6() {
  var data = _taggedTemplateLiteral(["\n  color: ", ";\n  text-transform: uppercase;\n  font-weight: 600;\n"]);

  _templateObject6 = function _templateObject6() {
    return data;
  };

  return data;
}

function _templateObject5() {
  var data = _taggedTemplateLiteral(["\n  padding: 1rem;\n"]);

  _templateObject5 = function _templateObject5() {
    return data;
  };

  return data;
}

function _templateObject4() {
  var data = _taggedTemplateLiteral(["\n  display: flex;\n  align-items: center;\n"]);

  _templateObject4 = function _templateObject4() {
    return data;
  };

  return data;
}

function _templateObject3() {
  var data = _taggedTemplateLiteral(["\n  line-height: 0;\n"]);

  _templateObject3 = function _templateObject3() {
    return data;
  };

  return data;
}

function _templateObject2() {
  var data = _taggedTemplateLiteral(["\n  display: flex;\n  flex-direction: row;\n  justify-content: space-between;\n  align-items: center;\n  max-height: ", ";\n  padding: 1.5em 0;\n  background-image: ", ";\n  background-position: bottom;\n  background-size: 12px 1px;\n  background-repeat: repeat-x;\n"]);

  _templateObject2 = function _templateObject2() {
    return data;
  };

  return data;
}

function _templateObject() {
  var data = _taggedTemplateLiteral(["\n  width: 100%;\n  background-color: ", ";\n  padding: 0 1.3rem;\n  font-size: 12;\n"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

import ReactSVG from "react-svg";
import { styled } from "@styles";
export var Wrapper = styled.section(_templateObject(), function (props) {
  return props.theme.colors.bannerBackground;
});
export var BorderedWrapper = styled.div(_templateObject2(), function (props) {
  return props.theme.demoBanner.height;
}, function (props) {
  return "linear-gradient(\n    to right,\n    ".concat(props.theme.colors.bannerEdge, " 13%,\n    rgba(255, 255, 255, 0) 0%\n  );");
});
export var LogoWrapper = styled(ReactSVG)(_templateObject3());
export var LinkList = styled.div(_templateObject4());
export var Link = styled.a(_templateObject5());
export var TextEmphasis = styled.span(_templateObject6(), function (props) {
  return props.theme.colors.bannerLink;
});
export var Divider = styled.div(_templateObject7(), function (props) {
  return props.theme.colors.bannerEdge;
});