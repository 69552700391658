import _extends from "@babel/runtime/helpers/extends";
import { compact } from "lodash";
import React, { useCallback } from "react";
import NumberFormat from "react-number-format";
import { TextField } from "@components/molecules";
import * as S from "./styles";

var getInputProps = function getInputProps(disabled, handleChange) {
  return function (label, errors, value) {
    return {
      customInput: TextField,
      disabled: disabled,
      errors: compact(errors),
      label: label,
      onChange: handleChange,
      value: value
    };
  };
};

export var CreditCardFormContent = function CreditCardFormContent(_ref) {
  var formRef = _ref.formRef,
      formId = _ref.formId,
      _ref$cardErrors = _ref.cardErrors,
      cardNumberError = _ref$cardErrors.number,
      ccCscError = _ref$cardErrors.cvv,
      expirationMonthError = _ref$cardErrors.expirationMonth,
      expirationYearError = _ref$cardErrors.expirationYear,
      disabled = _ref.disabled,
      _ref$labelsText = _ref.labelsText,
      ccCscText = _ref$labelsText.ccCsc,
      ccExpText = _ref$labelsText.ccExp,
      ccNumberText = _ref$labelsText.ccNumber,
      handleSubmit = _ref.handleSubmit,
      handleChange = _ref.handleChange,
      values = _ref.values;
  var basicInputProps = useCallback(getInputProps(disabled, handleChange), [disabled, handleChange]);
  return React.createElement(S.PaymentForm, {
    ref: formRef,
    id: formId,
    onSubmit: handleSubmit,
    "data-test": "creditCardForm"
  }, React.createElement(S.PaymentInput, null, React.createElement(NumberFormat, _extends({
    autoFocus: true,
    autoComplete: "cc-number",
    format: "#### #### #### ####",
    name: "ccNumber"
  }, basicInputProps(ccNumberText, [cardNumberError], values.ccNumber)))), React.createElement(S.Grid, null, React.createElement(S.PaymentInput, null, React.createElement(NumberFormat, _extends({
    autoComplete: "cc-csc",
    format: "####",
    name: "ccCsc"
  }, basicInputProps(ccCscText, [ccCscError], values.ccCsc)))), React.createElement(S.PaymentInput, null, React.createElement(NumberFormat, _extends({
    autoComplete: "cc-exp",
    format: "## / ##",
    name: "ccExp"
  }, basicInputProps(ccExpText, [expirationMonthError, expirationYearError], values.ccExp))))));
};