import _taggedTemplateLiteral from "@babel/runtime/helpers/taggedTemplateLiteral";

function _templateObject() {
  var data = _taggedTemplateLiteral(["\n  fragment SecondaryMenuSubItem on MenuItem {\n    id\n    name\n    category {\n      id\n      name\n    }\n    url\n    collection {\n      id\n      name\n    }\n    page {\n      slug\n    }\n  }\n\n  query SecondaryMenu {\n    shop {\n      navigation {\n        secondary {\n          items {\n            ...SecondaryMenuSubItem\n            children {\n              ...SecondaryMenuSubItem\n            }\n          }\n        }\n      }\n    }\n  }\n"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

import gql from "graphql-tag";
import { TypedQuery } from "../../core/queries";
var secondaryMenu = gql(_templateObject());
export var TypedSecondaryMenuQuery = TypedQuery(secondaryMenu);