import _slicedToArray from "@babel/runtime/helpers/slicedToArray";
import { Formik } from "formik";
import React from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { Button, Chip, ErrorMessage, Input } from "@components/atoms";
import { commonMessages } from "@temp/intl";
import * as S from "./styles";
export var DiscountForm = function DiscountForm(_ref) {
  var handleSubmit = _ref.handleSubmit,
      discount = _ref.discount,
      errors = _ref.errors,
      formId = _ref.formId,
      formRef = _ref.formRef;
  var promoCode = discount && discount.promoCode;

  var _React$useState = React.useState(""),
      _React$useState2 = _slicedToArray(_React$useState, 2),
      inputCode = _React$useState2[0],
      setInputCode = _React$useState2[1];

  var _React$useState3 = React.useState(promoCode),
      _React$useState4 = _slicedToArray(_React$useState3, 2),
      tempPromoCode = _React$useState4[0],
      setTempPromoCode = _React$useState4[1];

  var intl = useIntl();

  var handleApplyBtnClick = function handleApplyBtnClick(newInputCode) {
    setTempPromoCode(newInputCode);
    setInputCode("");
  };

  var handleRemoveBtnClick = function handleRemoveBtnClick(newInputCode) {
    setTempPromoCode(undefined);
    setInputCode(newInputCode);
  };

  return React.createElement(Formik, {
    initialValues: {
      errors: errors,
      inputCode: inputCode,
      tempPromoCode: tempPromoCode
    },
    enableReinitialize: true,
    onSubmit: function onSubmit(values, _ref2) {
      var setSubmitting = _ref2.setSubmitting;

      if (handleSubmit) {
        handleSubmit({
          promoCode: values.tempPromoCode
        });
      }

      setSubmitting(false);
    }
  }, function (_ref3) {
    var handleChange = _ref3.handleChange,
        handleSubmit = _ref3.handleSubmit,
        handleBlur = _ref3.handleBlur,
        values = _ref3.values,
        setFieldValue = _ref3.setFieldValue,
        setFieldTouched = _ref3.setFieldTouched;
    var hasErrors = !!(values.errors && values.errors.length);
    return React.createElement(S.DiscountForm, {
      id: formId,
      ref: formRef,
      onSubmit: handleSubmit,
      "data-test": "discountForm"
    }, React.createElement(S.Input, null, React.createElement(S.InputWithButton, null, React.createElement(S.InputWrapper, null, React.createElement(Input, {
      error: hasErrors,
      name: "inputCode",
      value: values.inputCode,
      label: intl.formatMessage(commonMessages.promoCode),
      onChange: handleChange
    })), React.createElement(S.ButtonWrapper, null, React.createElement(Button, {
      type: "button",
      testingContext: "applyPromoCodeButton",
      onClick: function onClick() {
        return handleApplyBtnClick(values.inputCode);
      }
    }, React.createElement(FormattedMessage, {
      id: "@next.components.organisms.DiscountForm.DiscountForm.3476123",
      defaultMessage: "Apply"
    })))), React.createElement(ErrorMessage, {
      errors: values.errors
    })), values.tempPromoCode && React.createElement(React.Fragment, null, React.createElement("span", null, React.createElement(FormattedMessage, commonMessages.promoCode), ":"), React.createElement(S.ChipsWrapper, null, React.createElement(Chip, {
      onClose: function onClose() {
        return handleRemoveBtnClick(values.inputCode);
      }
    }, React.createElement("span", {
      "data-test": "promoCodeChip"
    }, values.tempPromoCode)))));
  });
};