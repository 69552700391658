import _extends from "@babel/runtime/helpers/extends";
import _objectWithoutProperties from "@babel/runtime/helpers/objectWithoutProperties";
import React from "react";
import { Icon } from "../Icon";
import * as S from "./styles";
export var Chip = function Chip(_ref) {
  var _ref$color = _ref.color,
      color = _ref$color === void 0 ? "primary" : _ref$color,
      closeBtnRef = _ref.closeBtnRef,
      children = _ref.children,
      _ref$fullWidth = _ref.fullWidth,
      fullWidth = _ref$fullWidth === void 0 ? false : _ref$fullWidth,
      _ref$size = _ref.size,
      size = _ref$size === void 0 ? "md" : _ref$size,
      _ref$onClose = _ref.onClose,
      onClose = _ref$onClose === void 0 ? function () {
    return null;
  } : _ref$onClose,
      props = _objectWithoutProperties(_ref, ["color", "closeBtnRef", "children", "fullWidth", "size", "onClose"]);

  var ChipWithTheme = color === "primary" ? S.Primary : S.Secondary;
  return React.createElement(ChipWithTheme, _extends({
    color: color,
    fullWidth: fullWidth,
    size: size
  }, props), React.createElement(S.Text, {
    size: size
  }, children), React.createElement(S.CloseButton, {
    size: size,
    color: color,
    ref: closeBtnRef,
    onClick: onClose
  }, React.createElement(Icon, {
    name: "x_light",
    size: 16
  })));
};