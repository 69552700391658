import * as React from "react";
import { useShopDetails } from "@saleor/sdk";
import { maybe } from "../../core/utils";
import { defaultContext, ShopContext } from "./context";

var ShopProvider = function ShopProvider(_ref) {
  var children = _ref.children;

  var _useShopDetails = useShopDetails(),
      data = _useShopDetails.data;

  return React.createElement(ShopContext.Provider, {
    value: maybe(function () {
      return data.shop;
    }, defaultContext)
  }, children);
};

export default ShopProvider;