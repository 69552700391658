import _extends from "@babel/runtime/helpers/extends";
import "./scss/index.scss";
import * as React from "react";
import { FormattedMessage } from "react-intl";
import { commonMessages } from "@temp/intl";

var AddressSummary = function AddressSummary(_ref) {
  var address = _ref.address,
      email = _ref.email,
      paragraphRef = _ref.paragraphRef;

  if (address) {
    return React.createElement("p", {
      className: "address-summary",
      ref: paragraphRef
    }, React.createElement("strong", null, "".concat(address.firstName, " ").concat(address.lastName)), React.createElement("br", null), address.companyName && React.createElement(React.Fragment, null, address.companyName, " ", React.createElement("br", null)), address.streetAddress1, React.createElement("br", null), address.streetAddress2 && React.createElement(React.Fragment, null, address.streetAddress2, " ", React.createElement("br", null)), address.city, ", ", address.postalCode, React.createElement("br", null), address.countryArea && React.createElement(React.Fragment, null, address.countryArea, " ", React.createElement("br", null)), address.country.country, React.createElement("br", null), address.phone && React.createElement(React.Fragment, null, React.createElement(FormattedMessage, _extends({}, commonMessages.phoneNumber, {
      values: {
        phone: address.phone
      }
    })), " ", React.createElement("br", null)), email && React.createElement(React.Fragment, null, email, " ", React.createElement("br", null)));
  }

  if (email) {
    return React.createElement("p", {
      className: "address-summary",
      ref: paragraphRef
    }, email);
  }

  return null;
};

export default AddressSummary;