import React from "react";
import { TaxedMoney } from "@components/containers";
import { Thumbnail } from "@components/molecules";
import * as S from "./styles";
export var ProductTile = function ProductTile(_ref) {
  var product = _ref.product;
  var price = product.pricing && product.pricing.priceRange && product.pricing.priceRange.start ? product.pricing.priceRange.start : undefined;
  return React.createElement(S.Wrapper, null, React.createElement(S.Title, {
    "data-test": "productTile"
  }, product.name), React.createElement(S.Price, {
    "data-test": "productPrice"
  }, React.createElement(TaxedMoney, {
    taxedMoney: price
  })), React.createElement(S.Image, {
    "data-test": "productThumbnail"
  }, React.createElement(Thumbnail, {
    source: product
  })));
};