import _extends from "@babel/runtime/helpers/extends";
import _objectWithoutProperties from "@babel/runtime/helpers/objectWithoutProperties";

/* eslint-disable jsx-a11y/no-noninteractive-tabindex */
import React from "react";
import * as S from "./styles";
var ENTER_KEY = 13;
var SPACE_KEY = 32;
export var Checkbox = function Checkbox(_ref) {
  var name = _ref.name,
      checked = _ref.checked,
      _ref$onChange = _ref.onChange,
      onChange = _ref$onChange === void 0 ? function () {
    return null;
  } : _ref$onChange,
      children = _ref.children,
      props = _objectWithoutProperties(_ref, ["name", "checked", "onChange", "children"]);

  var ref = React.useRef(null);
  return React.createElement(S.Checkbox, {
    ref: ref,
    onClick: function onClick(evt) {
      evt.preventDefault();
      onChange(evt);

      if (ref.current) {
        ref.current.blur();
      }
    }
  }, React.createElement(S.Label, null, React.createElement("input", _extends({}, props, {
    tabIndex: -1,
    type: "checkbox",
    name: name,
    checked: checked,
    readOnly: true
  })), React.createElement("div", {
    ref: ref,
    tabIndex: 0,
    onKeyDown: function onKeyDown(evt) {
      if (evt.which === SPACE_KEY || evt.which === ENTER_KEY) {
        evt.preventDefault();
        onChange(evt);
      }
    }
  }, React.createElement("span", null))), children);
};