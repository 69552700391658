import _taggedTemplateLiteral from "@babel/runtime/helpers/taggedTemplateLiteral";

function _templateObject() {
  var data = _taggedTemplateLiteral(["\n  mutation ResetPasswordRequest($email: String!, $redirectUrl: String!) {\n    requestPasswordReset(email: $email, redirectUrl: $redirectUrl) {\n      errors {\n        field\n        message\n      }\n    }\n  }\n"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

import gql from "graphql-tag";
import { TypedMutation } from "../../core/mutations";
// fixme: this will be fixed in issue https://github.com/mirumee/saleor-storefront/issues/500
var passwordResetRequestMutation = gql(_templateObject());
export var TypedPasswordResetRequestMutation = TypedMutation(passwordResetRequestMutation);