import _extends from "@babel/runtime/helpers/extends";
import _objectWithoutProperties from "@babel/runtime/helpers/objectWithoutProperties";
import { Formik } from "formik";
import { pick } from "lodash";
import React from "react";
import { AddressFormContent } from "./AddressFormContent";
var ADDRESS_FIELDS = ["city", "companyName", "countryArea", "firstName", "lastName", "country", "phone", "postalCode", "streetAddress1", "streetAddress2", "email"];
export var AddressForm = function AddressForm(_ref) {
  var address = _ref.address,
      handleSubmit = _ref.handleSubmit,
      formId = _ref.formId,
      defaultValue = _ref.defaultValue,
      countriesOptions = _ref.countriesOptions,
      props = _objectWithoutProperties(_ref, ["address", "handleSubmit", "formId", "defaultValue", "countriesOptions"]);

  var addressWithPickedFields = {};

  if (address) {
    addressWithPickedFields = pick(address, ADDRESS_FIELDS);
  }

  if (defaultValue) {
    addressWithPickedFields.country = defaultValue;
  }

  return React.createElement(Formik, {
    initialValues: addressWithPickedFields,
    enableReinitialize: true,
    onSubmit: function onSubmit(values, _ref2) {
      var setSubmitting = _ref2.setSubmitting;

      if (handleSubmit) {
        handleSubmit(values);
      }

      setSubmitting(false);
    }
  }, function (_ref3) {
    var handleChange = _ref3.handleChange,
        handleSubmit = _ref3.handleSubmit,
        handleBlur = _ref3.handleBlur,
        values = _ref3.values,
        setFieldValue = _ref3.setFieldValue,
        setFieldTouched = _ref3.setFieldTouched;
    return React.createElement(AddressFormContent, _extends({
      countriesOptions: countriesOptions,
      defaultValue: defaultValue,
      formId: formId,
      handleBlur: handleBlur,
      handleChange: handleChange,
      handleSubmit: handleSubmit,
      setFieldTouched: setFieldTouched,
      setFieldValue: setFieldValue,
      values: values
    }, props));
  });
};