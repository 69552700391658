import * as React from "react";
import { FormattedMessage } from "react-intl";
export var NothingFound = function NothingFound(_ref) {
  var search = _ref.search;
  return React.createElement("div", {
    className: "search__products--not-found"
  }, React.createElement("p", {
    className: "u-lead u-lead--bold u-uppercase"
  }, React.createElement(FormattedMessage, {
    id: "components.OverlayManager.Search.NothingFound.3166222023",
    defaultMessage: "Sorry, but we couldn\u2019t match any search results for: {search}",
    values: {
      search: search
    }
  })), React.createElement("p", null, React.createElement(FormattedMessage, {
    id: "components.OverlayManager.Search.NothingFound.1777769494",
    defaultMessage: "Don\u2019t give up - check the spelling, think of something less specific and then use the search bar above."
  })));
};
export default NothingFound;