import _objectWithoutProperties from "@babel/runtime/helpers/objectWithoutProperties";
import React from "react";
export var Money = function Money(_ref) {
  var money = _ref.money,
      defaultValue = _ref.defaultValue,
      props = _objectWithoutProperties(_ref, ["money", "defaultValue"]);

  if (!money) {
    return React.createElement("span", props, defaultValue);
  }

  return React.createElement("span", props, money.currency && money.currency !== "" ? money.amount.toLocaleString(undefined, {
    currency: money.currency,
    style: "currency"
  }) : money.amount.toString());
};
Money.displayName = "Money";
export default Money;