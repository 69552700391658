import React from "react";
import { AddNewTile, TileGrid } from "@components/atoms";
import { CreditCardTile } from "@components/molecules";
export var CreditCardGrid = function CreditCardGrid(_ref) {
  var creditCards = _ref.creditCards;
  var addNewTile = [React.createElement(AddNewTile, {
    type: "card"
  })];
  var ccTiles = creditCards.map(function (cc) {
    return React.createElement(CreditCardTile, cc);
  });
  return React.createElement(TileGrid, {
    elements: addNewTile.concat(ccTiles)
  });
};