import _slicedToArray from "@babel/runtime/helpers/slicedToArray";
import { useEffect, useState } from "react";
import { CHECKOUT_STEPS } from "@temp/core/config";
/**
 * Gets checkout step based on the provided path.
 */

export var useCheckoutStepFromPath = function useCheckoutStepFromPath(path) {
  var getStep = function getStep() {
    var pathItem = CHECKOUT_STEPS.find(function (_ref) {
      var link = _ref.link;
      return path.replace(/\//g, "").includes(link.replace(/\//g, ""));
    });
    return pathItem ? pathItem.step : null;
  };

  var _useState = useState(getStep()),
      _useState2 = _slicedToArray(_useState, 2),
      step = _useState2[0],
      setStep = _useState2[1];

  useEffect(function () {
    var newStep = getStep();

    if (step !== newStep) {
      setStep(newStep);
    }
  }, [path]);
  return step;
};