import React from "react";
import * as S from "./styles";
export var InputLabel = function InputLabel(_ref) {
  var children = _ref.children,
      active = _ref.active,
      labelBackground = _ref.labelBackground;
  return React.createElement(S.Label, {
    active: active,
    labelBackground: labelBackground
  }, children);
};