import _taggedTemplateLiteral from "@babel/runtime/helpers/taggedTemplateLiteral";

function _templateObject5() {
  var data = _taggedTemplateLiteral(["\n  grid-area: cardCvc;\n"]);

  _templateObject5 = function _templateObject5() {
    return data;
  };

  return data;
}

function _templateObject4() {
  var data = _taggedTemplateLiteral(["\n  grid-area: cardExpiry;\n"]);

  _templateObject4 = function _templateObject4() {
    return data;
  };

  return data;
}

function _templateObject3() {
  var data = _taggedTemplateLiteral(["\n  grid-area: cardNumber;\n"]);

  _templateObject3 = function _templateObject3() {
    return data;
  };

  return data;
}

function _templateObject2() {
  var data = _taggedTemplateLiteral(["\n  display: grid;\n  grid-template-areas:\n    \"cardNumber cardNumber cardNumber cardNumber cardNumber \"\n    \"cardExpiry cardExpiry cardExpiry cardCvc cardCvc\";\n  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;\n  grid-gap: ", ";\n\n  .StripeElement {\n    padding: 0.8rem 1rem;\n    margin: 0;\n    width: 100%;\n    font-size: ", ";\n    outline: none;\n    background-color: transparent;\n    ::placeholder {\n      font-size: 0;\n      visibility: hidden;\n    }\n    &--empty {\n      font-size: 0;\n    }\n  }\n"]);

  _templateObject2 = function _templateObject2() {
    return data;
  };

  return data;
}

function _templateObject() {
  var data = _taggedTemplateLiteral(["\n  padding: 2rem 0;\n"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

import { styled } from "@styles";
export var Form = styled.form(_templateObject());
export var Card = styled.div(_templateObject2(), function (props) {
  return props.theme.spacing.fieldSpacer;
}, function (props) {
  return props.theme.typography.baseFontSize;
});
export var CardNumberField = styled.div(_templateObject3());
export var CardExpiryField = styled.div(_templateObject4());
export var CardCvcField = styled.div(_templateObject5());