import "./scss/index.scss";
import * as React from "react";
import { FormattedMessage } from "react-intl";
import { Link } from "react-router-dom";
import { BASE_URL } from "../../core/config";
import Button from "../Button";

var NotFound = function NotFound() {
  return React.createElement("div", {
    className: "not-found-page"
  }, React.createElement("h2", {
    className: "not-found-page__header"
  }, React.createElement(FormattedMessage, {
    id: "components.NotFound.index.3478767873",
    defaultMessage: "404"
  })), React.createElement("div", {
    className: "not-found-page__ruler"
  }), React.createElement("div", {
    className: "not-found-page__message"
  }, React.createElement("p", null, React.createElement(FormattedMessage, {
    id: "components.NotFound.index.680965574",
    defaultMessage: "We can\u2019t seem to find a page you are looking for!"
  }), " "), React.createElement("p", null, React.createElement(FormattedMessage, {
    id: "components.NotFound.index.1782529840",
    defaultMessage: "You may have mistyped the address or the page may have moved."
  }), " "), React.createElement("p", null, React.createElement(FormattedMessage, {
    id: "components.NotFound.index.3141549744",
    defaultMessage: "We\u2019re sorry for the error and hope you\u2019ll have a good day."
  }))), React.createElement("div", {
    className: "not-found-page__button"
  }, React.createElement(Link, {
    to: BASE_URL
  }, React.createElement(Button, {
    testingContext: "404pageGotoHomeButton",
    secondary: true
  }, React.createElement(FormattedMessage, {
    id: "components.NotFound.index.1723676032",
    defaultMessage: "Back to home"
  })))));
};

export default NotFound;