import * as React from "react";
import ReactSVG from "react-svg";
import "./scss/index.scss";

var SocialMediaIcon = function SocialMediaIcon(_ref) {
  var medium = _ref.medium,
      target = _ref.target;
  return React.createElement("a", {
    href: medium.href,
    target: target || "_blank",
    "aria-label": medium.ariaLabel
  }, React.createElement(ReactSVG, {
    path: medium.path,
    className: "social-icon"
  }));
};

export default SocialMediaIcon;