import * as React from "react";
import { MobileNavList, Overlay } from "..";

var MobileNav = function MobileNav(_ref) {
  var overlay = _ref.overlay;
  var items = overlay.context.data;
  return React.createElement(Overlay, {
    testingContext: "mobileNavigationOverlay",
    context: overlay
  }, React.createElement("div", {
    className: "side-nav",
    onClick: function onClick(evt) {
      return evt.stopPropagation();
    }
  }, React.createElement(MobileNavList, {
    items: items,
    hideOverlay: overlay.hide
  })));
};

export default MobileNav;