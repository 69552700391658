import _taggedTemplateLiteral from "@babel/runtime/helpers/taggedTemplateLiteral";

function _templateObject6() {
  var data = _taggedTemplateLiteral(["\n  grid-area: quantity;\n  color: ", ";\n  font-size: ", ";\n"]);

  _templateObject6 = function _templateObject6() {
    return data;
  };

  return data;
}

function _templateObject5() {
  var data = _taggedTemplateLiteral(["\n  grid-area: price;\n  text-align: right;\n  font-size: ", ";\n"]);

  _templateObject5 = function _templateObject5() {
    return data;
  };

  return data;
}

function _templateObject4() {
  var data = _taggedTemplateLiteral(["\n  grid-area: name;\n  font-size: ", ";\n"]);

  _templateObject4 = function _templateObject4() {
    return data;
  };

  return data;
}

function _templateObject3() {
  var data = _taggedTemplateLiteral(["\n  grid-area: sku;\n  color: ", ";\n  font-size: ", ";\n"]);

  _templateObject3 = function _templateObject3() {
    return data;
  };

  return data;
}

function _templateObject2() {
  var data = _taggedTemplateLiteral(["\n  grid-area: photo;\n  width: min-content;\n\n  img {\n    height: auto;\n    max-width: 60px;\n  }\n"]);

  _templateObject2 = function _templateObject2() {
    return data;
  };

  return data;
}

function _templateObject() {
  var data = _taggedTemplateLiteral(["\n  display: grid;\n  grid-row-gap: 6px;\n  grid-column-gap: 20px;\n  grid-template-columns: 60px auto auto;\n  grid-template-areas:\n    \"photo name name\"\n    \"photo sku .\"\n    \"photo . .\"\n    \"photo quantity price\";\n"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

import { styled } from "@styles";
export var Wrapper = styled.div(_templateObject());
export var Photo = styled.div(_templateObject2());
export var Sku = styled.div(_templateObject3(), function (props) {
  return props.theme.colors.baseFontColorSemiTransparent;
}, function (props) {
  return props.theme.typography.smallFontSize;
});
export var Name = styled.div(_templateObject4(), function (props) {
  return props.theme.typography.h4FontSize;
});
export var Price = styled.div(_templateObject5(), function (props) {
  return props.theme.typography.smallFontSize;
});
export var Quantity = styled.div(_templateObject6(), function (props) {
  return props.theme.colors.baseFontColorSemiTransparent;
}, function (props) {
  return props.theme.typography.smallFontSize;
});