import * as React from "react";
import { maybe } from "@utils/tsUtils";
export var useHandlerWhenClickedOutside = function useHandlerWhenClickedOutside(callback) {
  var elementRef = React.useRef(null);

  var handleClickOutside = function handleClickOutside(e) {
    if (maybe(function () {
      return elementRef.current && e.target;
    }, null)) {
      if (elementRef.current.contains(e.target)) {
        return;
      }

      callback();
    }
  };

  var setElementRef = function setElementRef() {
    return elementRef;
  };

  React.useEffect(function () {
    document.addEventListener("mousedown", handleClickOutside);
    return function () {
      return document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);
  return {
    setElementRef: setElementRef
  };
};