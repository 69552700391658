import * as React from "react";
import "./scss/index.scss";

var Loader = function Loader(_ref) {
  var full = _ref.full;

  var getHeight = function getHeight() {
    var headerHeight = document.getElementById("header") && document.getElementById("header").offsetHeight;
    var footerHeight = document.getElementById("footer") && document.getElementById("footer").offsetHeight;
    return window.innerHeight - headerHeight - footerHeight;
  };

  return React.createElement("div", {
    className: "loader",
    style: full && {
      height: getHeight()
    }
  }, React.createElement("div", {
    className: "loader__items"
  }, React.createElement("span", null), React.createElement("span", null), React.createElement("span", null)));
};

export default Loader;