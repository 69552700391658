import _taggedTemplateLiteral from "@babel/runtime/helpers/taggedTemplateLiteral";

function _templateObject5() {
  var data = _taggedTemplateLiteral(["\n      padding-right:  0;\n      \n    "]);

  _templateObject5 = function _templateObject5() {
    return data;
  };

  return data;
}

function _templateObject4() {
  var data = _taggedTemplateLiteral(["\n     flex-direction: column;\n  "]);

  _templateObject4 = function _templateObject4() {
    return data;
  };

  return data;
}

function _templateObject3() {
  var data = _taggedTemplateLiteral(["\n  display: flex;\n  justify-content: space-between;\n  ", "\n  & > div {\n    padding-right: ", ";\n    &:last-child {\n      padding-right: 0;\n    }\n    ", "\n  }\n"]);

  _templateObject3 = function _templateObject3() {
    return data;
  };

  return data;
}

function _templateObject2() {
  var data = _taggedTemplateLiteral(["\n  width: 100%;\n"]);

  _templateObject2 = function _templateObject2() {
    return data;
  };

  return data;
}

function _templateObject() {
  var data = _taggedTemplateLiteral(["\n  width: 100%;\n"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

import { media, styled } from "@styles";
export var PaymentForm = styled.form(_templateObject());
export var PaymentInput = styled.div(_templateObject2());
PaymentInput.displayName = "S.PaymentInput";
export var Grid = styled.div(_templateObject3(), media.smallScreen(_templateObject4()), function (props) {
  return props.theme.spacing.spacer;
}, media.smallScreen(_templateObject5()));