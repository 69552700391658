import React from "react";
import { IconButton, Tile } from "@components/atoms/";
import { CreditCardNumberWithIcon } from "../CreditCardNumberWithIcon";
import * as S from "./styles";
export var CreditCardTile = function CreditCardTile(_ref) {
  var nameOnCard = _ref.nameOnCard,
      expirationDate = _ref.expirationDate,
      onRemove = _ref.onRemove,
      last4Digits = _ref.last4Digits,
      creditCardProvider = _ref.creditCardProvider;
  var header = React.createElement(CreditCardNumberWithIcon, {
    last4Digits: last4Digits,
    creditCardProvider: creditCardProvider
  });
  var content = React.createElement(React.Fragment, null, React.createElement(S.BoldTitle, null, "Expires on"), React.createElement(S.TextContent, {
    "data-test": "expirationDate"
  }, expirationDate), React.createElement(S.BoldTitle, null, "Name on card"), React.createElement(S.TextContent, {
    "data-test": "nameOnCard"
  }, nameOnCard));
  var footer = React.createElement(S.FooterContent, null, React.createElement("div", null, React.createElement(IconButton, {
    name: "trash",
    onClick: onRemove,
    size: 22,
    testingContext: "removeCardButton"
  })));
  return React.createElement(Tile, {
    header: header,
    footer: footer,
    "data-test": "creditCardTile",
    "data-test-id": last4Digits
  }, content);
};