import * as React from "react";
import { Message } from "../Message";
export var NotificationTemplate = function NotificationTemplate(_ref) {
  var message = _ref.message,
      options = _ref.options,
      close = _ref.close;
  return React.createElement(Message, {
    actionText: message.actionText,
    status: options.type,
    title: message.title,
    onClick: close
  }, message.content);
};