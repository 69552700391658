import React from "react";
import { FormattedMessage } from "react-intl";
import { LoginForm } from "..";
import ForgottenPassword from "../OverlayManager/Login/ForgottenPassword";

var SignInForm = function SignInForm(_ref) {
  var onForgottenPasswordClick = _ref.onForgottenPasswordClick;
  return React.createElement(React.Fragment, null, React.createElement("h3", {
    className: "checkout__header"
  }, React.createElement(FormattedMessage, {
    id: "components.CheckoutLogin.SignInForm.2856837597",
    defaultMessage: "Registered user"
  })), React.createElement(LoginForm, null), React.createElement(ForgottenPassword, {
    onClick: onForgottenPasswordClick
  }));
};

export default SignInForm;