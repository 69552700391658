import * as React from "react";
export var OverlayType;

(function (OverlayType) {
  OverlayType["cart"] = "cart";
  OverlayType["checkout"] = "checkout";
  OverlayType["login"] = "login";
  OverlayType["message"] = "message";
  OverlayType["sideNav"] = "side-nav";
  OverlayType["password"] = "password";
  OverlayType["search"] = "search";
  OverlayType["mainMenuNav"] = "main-menu-nav";
  OverlayType["modal"] = "modal";
  OverlayType["register"] = "register";
})(OverlayType || (OverlayType = {}));

export var OverlayTheme;

(function (OverlayTheme) {
  OverlayTheme["left"] = "left";
  OverlayTheme["right"] = "right";
  OverlayTheme["modal"] = "modal";
})(OverlayTheme || (OverlayTheme = {}));

/* tslint:disable:no-empty */
export var OverlayContext = React.createContext({
  context: null,
  hide: function hide() {},
  show: function show(type) {},
  theme: null,
  type: null
});
/* tslint:enable:no-empty */

OverlayContext.displayName = "OverlayContext";