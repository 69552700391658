import _extends from "@babel/runtime/helpers/extends";
import * as React from "react";
import Select from "react-select";
import "./scss/index.scss";

var Dropdown = function Dropdown(props) {
  return React.createElement(Select, _extends({
    classNamePrefix: "dropdown",
    className: "dropdown-component"
  }, props));
};

export default Dropdown;