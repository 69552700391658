import _defineProperty from "@babel/runtime/helpers/defineProperty";
import "./scss/index.scss";
import classNames from "classnames";
import * as React from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { Link } from "react-router-dom";
import { Button, Loader, ProductsFeatured } from "../../components";
import { generateCategoryUrl } from "../../core/utils";
import { structuredData } from "../../core/SEO/Homepage/structuredData";
import noPhotoImg from "../../images/no-photo.svg";

var Page = function Page(_ref) {
  var loading = _ref.loading,
      categories = _ref.categories,
      backgroundImage = _ref.backgroundImage,
      shop = _ref.shop;

  var categoriesExist = function categoriesExist() {
    return categories && categories.edges && categories.edges.length > 0;
  };

  var intl = useIntl();
  return React.createElement(React.Fragment, null, React.createElement("script", {
    className: "structured-data-list",
    type: "application/ld+json"
  }, structuredData(shop)), React.createElement("div", {
    className: "home-page__hero",
    style: backgroundImage ? {
      backgroundImage: "url(".concat(backgroundImage.url, ")")
    } : null
  }, React.createElement("div", {
    className: "home-page__hero-text"
  }, React.createElement("div", null, React.createElement("span", {
    className: "home-page__hero__title"
  }, React.createElement("h1", null, React.createElement(FormattedMessage, {
    id: "views.Home.Page.2366307429",
    defaultMessage: "Final reduction"
  })))), React.createElement("div", null, React.createElement("span", {
    className: "home-page__hero__title"
  }, React.createElement("h1", null, React.createElement(FormattedMessage, {
    id: "views.Home.Page.4153800196",
    defaultMessage: "Up to 70% off sale"
  }))))), React.createElement("div", {
    className: "home-page__hero-action"
  }, loading && !categories ? React.createElement(Loader, null) : categoriesExist() && React.createElement(Link, {
    to: generateCategoryUrl(categories.edges[0].node.id, categories.edges[0].node.name)
  }, React.createElement(Button, {
    testingContext: "homepageHeroActionButton"
  }, React.createElement(FormattedMessage, {
    id: "views.Home.Page.3347022999",
    defaultMessage: "Shop sale"
  }))))), React.createElement(ProductsFeatured, {
    title: intl.formatMessage(_defineProperty({
      defaultMessage: "Featured",
      "id": "views.Home.Page.2812648978"
    }, "id", "views.Home.Page.2812648978"))
  }), categoriesExist() && React.createElement("div", {
    className: "home-page__categories"
  }, React.createElement("div", {
    className: "container"
  }, React.createElement("h3", null, React.createElement(FormattedMessage, {
    id: "views.Home.Page.3813745889",
    defaultMessage: "Shop by category"
  })), React.createElement("div", {
    className: "home-page__categories__list"
  }, categories.edges.map(function (_ref2) {
    var category = _ref2.node;
    return React.createElement("div", {
      key: category.id
    }, React.createElement(Link, {
      to: generateCategoryUrl(category.id, category.name),
      key: category.id
    }, React.createElement("div", {
      className: classNames("home-page__categories__list__image", {
        "home-page__categories__list__image--no-photo": !category.backgroundImage
      }),
      style: {
        backgroundImage: "url(".concat(category.backgroundImage ? category.backgroundImage.url : noPhotoImg, ")")
      }
    }), React.createElement("h3", null, category.name)));
  })))));
};

export default Page;