import _slicedToArray from "@babel/runtime/helpers/slicedToArray";
// Rework this functionality once slugs are in use
// @ts-ignore
import { Base64 } from "js-base64";
export var slugify = function slugify(text) {
  return text.toString().toLowerCase().trim().replace(/\s+/g, "-") // Replace spaces with -
  .replace(/&/g, "-and-") // Replace & with 'and'
  .replace(/[^\w\-]+/g, "") // Remove all non-word chars
  .replace(/\-\-+/g, "-");
}; // Replace multiple - with single -

export var getDBIdFromGraphqlId = function getDBIdFromGraphqlId(graphqlId, schema) {
  // This is temporary solution, we will use slugs in the future
  var rawId = Base64.decode(graphqlId);
  var regexp = /(\w+):(\d+)/;

  var _ref = regexp.exec(rawId),
      _ref2 = _slicedToArray(_ref, 3),
      expectedSchema = _ref2[1],
      id = _ref2[2];

  if (schema && schema !== expectedSchema) {
    throw new Error("Schema is not correct");
  }

  return parseInt(id, 10);
};
export var generateCategoryUrl = function generateCategoryUrl(id, name) {
  return "/category/".concat(slugify(name), "/").concat(getDBIdFromGraphqlId(id, "Category"), "/");
};
export var generateCollectionUrl = function generateCollectionUrl(id, name) {
  return "/collection/".concat(slugify(name), "/").concat(getDBIdFromGraphqlId(id, "Collection"), "/");
};
export var generatePageUrl = function generatePageUrl(slug) {
  return "/page/".concat(slug, "/");
};
export var generateGuestOrderDetailsUrl = function generateGuestOrderDetailsUrl(token) {
  return "/order-history/".concat(token, "/");
};
export var checkIfShippingRequiredForProducts = function checkIfShippingRequiredForProducts(items) {
  return items === null || items === void 0 ? void 0 : items.some(function (_ref3) {
    var _variant$product;

    var variant = _ref3.variant;
    return (_variant$product = variant.product) === null || _variant$product === void 0 ? void 0 : _variant$product.productType.isShippingRequired;
  });
};