export function maybe(exp, d) {
  try {
    var result = exp();
    return result === undefined ? d : result;
  } catch (_unused) {
    return d;
  }
}
export function filterNotEmptyArrayItems(value) {
  return value !== null && value !== undefined;
}