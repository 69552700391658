import _slicedToArray from "@babel/runtime/helpers/slicedToArray";
import classNames from "classnames";
import React from "react";
import Media from "react-media";
import { ProductDescription } from "@components/molecules";
import { ProductGallery } from "@components/organisms";
import AddToCartSection from "@components/organisms/AddToCartSection";
import { smallScreen } from "../../globalStyles/scss/variables.scss";
import { Breadcrumbs, OverlayContext, OverlayTheme, OverlayType } from "../../components";
import { generateCategoryUrl, generateProductUrl } from "../../core/utils";
import GalleryCarousel from "./GalleryCarousel";
import OtherProducts from "./Other";
import { structuredData } from "../../core/SEO/Product/structuredData";

var populateBreadcrumbs = function populateBreadcrumbs(product) {
  return [{
    link: generateCategoryUrl(product.category.id, product.category.name),
    value: product.category.name
  }, {
    link: generateProductUrl(product.id, product.name),
    value: product.name
  }];
};

var Page = function Page(_ref) {
  var add = _ref.add,
      product = _ref.product,
      items = _ref.items,
      queryAttributes = _ref.queryAttributes,
      onAttributeChangeHandler = _ref.onAttributeChangeHandler;
  var overlayContext = React.useContext(OverlayContext);
  var productGallery = React.useRef();

  var _React$useState = React.useState(""),
      _React$useState2 = _slicedToArray(_React$useState, 2),
      variantId = _React$useState2[0],
      setVariantId = _React$useState2[1];

  var getImages = function getImages() {
    if (product.variants && variantId) {
      var variant = product.variants.find(function (variant) {
        return variant.id === variantId;
      });

      if (variant.images.length > 0) {
        return variant.images;
      }
    }

    return product.images;
  };

  var handleAddToCart = function handleAddToCart(variantId, quantity) {
    add(variantId, quantity);
    overlayContext.show(OverlayType.cart, OverlayTheme.right);
  };

  var addToCartSection = React.createElement(AddToCartSection, {
    items: items,
    productId: product.id,
    name: product.name,
    productVariants: product.variants,
    productPricing: product.pricing,
    queryAttributes: queryAttributes,
    setVariantId: setVariantId,
    variantId: variantId,
    onAddToCart: handleAddToCart,
    onAttributeChangeHandler: onAttributeChangeHandler,
    isAvailableForPurchase: product.isAvailableForPurchase,
    availableForPurchase: product.availableForPurchase
  });
  return React.createElement("div", {
    className: "product-page"
  }, React.createElement("div", {
    className: "container"
  }, React.createElement(Breadcrumbs, {
    breadcrumbs: populateBreadcrumbs(product)
  })), React.createElement("div", {
    className: "container"
  }, React.createElement("div", {
    className: "product-page__product"
  }, React.createElement("script", {
    className: "structured-data-list",
    type: "application/ld+json"
  }, structuredData(product)), React.createElement(Media, {
    query: {
      maxWidth: smallScreen
    }
  }, function (matches) {
    return matches ? React.createElement(React.Fragment, null, React.createElement(GalleryCarousel, {
      images: getImages()
    }), React.createElement("div", {
      className: "product-page__product__info"
    }, addToCartSection)) : React.createElement(React.Fragment, null, React.createElement("div", {
      className: "product-page__product__gallery",
      ref: productGallery
    }, React.createElement(ProductGallery, {
      images: getImages()
    })), React.createElement("div", {
      className: "product-page__product__info"
    }, React.createElement("div", {
      className: classNames("product-page__product__info--fixed")
    }, addToCartSection)));
  }))), React.createElement("div", {
    className: "container"
  }, React.createElement("div", {
    className: "product-page__product__description"
  }, React.createElement(ProductDescription, {
    descriptionJson: product.descriptionJson,
    attributes: product.attributes
  }))), React.createElement(OtherProducts, {
    products: product.category.products.edges
  }));
};

export default Page;