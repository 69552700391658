import _defineProperty from "@babel/runtime/helpers/defineProperty";
import "./scss/index.scss";
import classNames from "classnames";
import * as React from "react";

var Overlay = function Overlay(_ref) {
  var _classNames;

  var children = _ref.children,
      className = _ref.className,
      _ref$context = _ref.context,
      type = _ref$context.type,
      theme = _ref$context.theme,
      hide = _ref$context.hide,
      testingContext = _ref.testingContext;
  return React.createElement("div", {
    className: classNames("overlay", (_classNames = {}, _defineProperty(_classNames, "overlay--".concat(type), !!type), _defineProperty(_classNames, className, !!className), _classNames)),
    "data-test": testingContext,
    onClick: hide
  }, React.createElement("div", {
    className: "overlay__".concat(theme),
    onClick: function onClick(e) {
      return e.stopPropagation();
    }
  }, children));
};

export default Overlay;