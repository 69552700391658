import _extends from "@babel/runtime/helpers/extends";
import _objectWithoutProperties from "@babel/runtime/helpers/objectWithoutProperties";
import React from "react";
import { maybe } from "@utils/misc";
import { PlaceholderImage } from "@components/atoms";
import { CachedImage } from "..";
export var Thumbnail = function Thumbnail(_ref) {
  var source = _ref.source,
      children = _ref.children,
      props = _objectWithoutProperties(_ref, ["source", "children"]);

  var thumbnail = source.thumbnail,
      thumbnail2x = source.thumbnail2x;

  if (!thumbnail && !thumbnail2x) {
    return React.createElement(PlaceholderImage, null);
  }

  return React.createElement(CachedImage, _extends({}, props, {
    url: maybe(function () {
      return thumbnail.url;
    }),
    url2x: maybe(function () {
      return thumbnail2x.url;
    }),
    alt: maybe(function () {
      return thumbnail.alt ? thumbnail.alt : "";
    }, "")
  }), children);
};