import classNames from "classnames";
import * as React from "react";
import { FormattedMessage } from "react-intl";
import Media from "react-media";
import { Link } from "react-router-dom";
import { commonMessages } from "@temp/intl";
import { baseUrl } from "../../app/routes";
import { getDBIdFromGraphqlId, slugify } from "../../core/utils";
import { smallScreen } from "../../globalStyles/scss/variables.scss";
import "./scss/index.scss";
export var extractBreadcrumbs = function extractBreadcrumbs(category) {
  var constructLink = function constructLink(item) {
    return {
      link: ["/category", "/".concat(slugify(item.name)), "/".concat(getDBIdFromGraphqlId(item.id, "Category"), "/")].join(""),
      value: item.name
    };
  };

  var breadcrumbs = [constructLink(category)];

  if (category.ancestors.edges.length) {
    var ancestorsList = category.ancestors.edges.map(function (edge) {
      return constructLink(edge.node);
    });
    breadcrumbs = ancestorsList.concat(breadcrumbs);
  }

  return breadcrumbs;
};

var getBackLink = function getBackLink(breadcrumbs) {
  return breadcrumbs.length > 1 ? breadcrumbs[breadcrumbs.length - 2].link : "/";
};

var Breadcrumbs = function Breadcrumbs(_ref) {
  var breadcrumbs = _ref.breadcrumbs;
  return React.createElement(Media, {
    query: {
      minWidth: smallScreen
    }
  }, function (matches) {
    return matches ? React.createElement("ul", {
      className: "breadcrumbs"
    }, React.createElement("li", null, React.createElement(Link, {
      to: baseUrl
    }, React.createElement(FormattedMessage, commonMessages.home))), breadcrumbs.map(function (breadcrumb, index) {
      return React.createElement("li", {
        key: breadcrumb.value,
        className: classNames({
          breadcrumbs__active: index === breadcrumbs.length - 1
        })
      }, React.createElement(Link, {
        to: breadcrumb.link
      }, breadcrumb.value));
    })) : React.createElement("div", {
      className: "breadcrumbs"
    }, React.createElement(Link, {
      to: getBackLink(breadcrumbs)
    }, React.createElement(FormattedMessage, {
      id: "components.Breadcrumbs.index.1347475195",
      defaultMessage: "Back"
    })));
  });
};

export default Breadcrumbs;