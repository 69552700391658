import _extends from "@babel/runtime/helpers/extends";
import React from "react";
import { CardHeader, FormFooter } from "@components/molecules";
import { Overlay } from "@components/organisms";
import * as S from "./styles";

var getCancelBtnProps = function getCancelBtnProps(action, text) {
  return text && {
    cancelBtn: {
      action: action,
      testingContext: "cancelButton",
      text: text
    }
  };
};

var getSubmitBtnProps = function getSubmitBtnProps(text, submitButtonTestingContext, action) {
  return {
    submitBtn: action ? {
      action: action,
      testingContext: submitButtonTestingContext,
      text: text
    } : {
      testingContext: submitButtonTestingContext,
      text: text
    }
  };
};

export var Modal = function Modal(_ref) {
  var cancelBtnText = _ref.cancelBtnText,
      children = _ref.children,
      disabled = _ref.disabled,
      hide = _ref.hide,
      _ref$formId = _ref.formId,
      formId = _ref$formId === void 0 ? "modal-submit" : _ref$formId,
      onSubmit = _ref.onSubmit,
      submitBtnText = _ref.submitBtnText,
      submitButtonTestingContext = _ref.submitButtonTestingContext,
      show = _ref.show,
      target = _ref.target,
      testingContext = _ref.testingContext,
      title = _ref.title;
  return React.createElement(Overlay, {
    testingContext: testingContext,
    position: "center",
    show: show,
    hide: hide,
    target: target
  }, React.createElement(S.Modal, null, React.createElement(CardHeader, {
    divider: true,
    onHide: hide
  }, title), React.createElement(S.Content, null, children), React.createElement(FormFooter, _extends({
    divider: true,
    disabled: disabled
  }, getSubmitBtnProps(submitBtnText, submitButtonTestingContext, onSubmit), getCancelBtnProps(hide, cancelBtnText), {
    formId: formId
  }))));
};