import React from "react";
import { Icon } from "@components/atoms";
import * as S from "./styles";
export var OverlayItem = function OverlayItem(_ref) {
  var children = _ref.children,
      selected = _ref.selected,
      testingContextId = _ref.testingContextId,
      disabled = _ref.disabled,
      onClick = _ref.onClick;
  return React.createElement(S.Wrapper, {
    selected: !!selected,
    disabled: !!disabled,
    onClick: onClick,
    "data-test": "attributeOption",
    "data-test-id": testingContextId
  }, children, selected && React.createElement(Icon, {
    name: "tick",
    size: 16,
    "data-test": "chosenIcon"
  }));
};