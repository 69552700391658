import * as React from "react";

var CostRow = function CostRow(_ref) {
  var mediumScreen = _ref.mediumScreen,
      heading = _ref.heading,
      cost = _ref.cost;
  return React.createElement("tr", null, React.createElement("td", {
    colSpan: mediumScreen ? 4 : 3,
    className: "cart-table__cost"
  }, heading), React.createElement("td", {
    colSpan: 2
  }, cost));
};

export default CostRow;