import React from "react";
import { FormattedMessage } from "react-intl";
import { Link } from "react-router-dom";
import { Button, OverlayTheme, OverlayType } from "..";

var CheckoutAsGuest = function CheckoutAsGuest(_ref) {
  var overlay = _ref.overlay,
      checkoutUrl = _ref.checkoutUrl;
  return React.createElement("div", {
    className: "checkout-login__guest"
  }, React.createElement("h3", {
    className: "checkout__header"
  }, React.createElement(FormattedMessage, {
    id: "components.CheckoutLogin.CheckoutAsGuest.1006283488",
    defaultMessage: "Continue as a guest"
  })), React.createElement("p", null, React.createElement(FormattedMessage, {
    id: "components.CheckoutLogin.CheckoutAsGuest.647198093",
    defaultMessage: "If you don\u2019t wish to register an account, don\u2019t worry. You can checkout as a guest. We care about you just as much as any registered user."
  })), React.createElement(Link, {
    to: checkoutUrl
  }, React.createElement(Button, {
    testingContext: "continueAsGuestButton"
  }, React.createElement(FormattedMessage, {
    id: "components.CheckoutLogin.CheckoutAsGuest.1006283488",
    defaultMessage: "Continue as a guest"
  }))), React.createElement("p", null, React.createElement(FormattedMessage, {
    id: "components.CheckoutLogin.CheckoutAsGuest.3896074202",
    defaultMessage: "or you can"
  }), " ", React.createElement("span", {
    "data-test": "showRegisterOverlay",
    className: "u-link",
    onClick: function onClick() {
      return overlay.show(OverlayType.register, OverlayTheme.right);
    }
  }, React.createElement(FormattedMessage, {
    id: "components.CheckoutLogin.CheckoutAsGuest.2132450234",
    defaultMessage: "create an account"
  }))));
};

export default CheckoutAsGuest;