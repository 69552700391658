import _defineProperty from "@babel/runtime/helpers/defineProperty";
import _objectSpread from "@babel/runtime/helpers/objectSpread";
import _toConsumableArray from "@babel/runtime/helpers/toConsumableArray";
import _slicedToArray from "@babel/runtime/helpers/slicedToArray";
import { Base64 } from "js-base64";
import { each } from "lodash";
import { parse as parseQs, stringify as stringifyQs } from "query-string";
import { OrderDirection, ProductOrderField } from "../../gqlTypes/globalTypes";
export var slugify = function slugify(text) {
  return text.toString().toLowerCase().trim().replace(/\s+/g, "-") // Replace spaces with -
  .replace(/&/g, "-and-") // Replace & with 'and'
  .replace(/[^\w\-]+/g, "") // Remove all non-word chars
  .replace(/\-\-+/g, "-");
}; // Replace multiple - with single -

export var getDBIdFromGraphqlId = function getDBIdFromGraphqlId(graphqlId, schema) {
  // This is temporary solution, we will use slugs in the future
  var rawId = Base64.decode(graphqlId);
  var regexp = /(\w+):(\d+)/;
  var arr = regexp.exec(rawId);

  if (schema && schema !== arr[1]) {
    throw new Error("Schema is not correct");
  }

  return parseInt(arr[2], 10);
};
export var getGraphqlIdFromDBId = function getGraphqlIdFromDBId(id, schema) {
  return (// This is temporary solution, we will use slugs in the future
    Base64.encode("".concat(schema, ":").concat(id))
  );
};
export var priceToString = function priceToString(price, locale) {
  var amount = price.amount;

  if (locale) {
    return amount.toLocaleString(locale, {
      currency: price.currency,
      style: "currency"
    });
  }

  return "".concat(price.currency, " ").concat(amount.toFixed(2));
};
export var generateProductUrl = function generateProductUrl(id, name) {
  return "/product/".concat(slugify(name), "/").concat(getDBIdFromGraphqlId(id, "Product"), "/");
};
export var generateCategoryUrl = function generateCategoryUrl(id, name) {
  return "/category/".concat(slugify(name), "/").concat(getDBIdFromGraphqlId(id, "Category"), "/");
};
export var generateCollectionUrl = function generateCollectionUrl(id, name) {
  return "/collection/".concat(slugify(name), "/").concat(getDBIdFromGraphqlId(id, "Collection"), "/");
};
export var generatePageUrl = function generatePageUrl(slug) {
  return "/page/".concat(slug, "/");
};
export var convertToAttributeScalar = function convertToAttributeScalar(attributes) {
  return Object.entries(attributes).map(function (_ref) {
    var _ref2 = _slicedToArray(_ref, 2),
        key = _ref2[0],
        value = _ref2[1];

    return value.map(function (attribute) {
      return {
        slug: key,
        value: attribute
      };
    });
  }).reduce(function (prev, curr) {
    return [].concat(_toConsumableArray(prev), _toConsumableArray(curr));
  }, []);
};
export var getAttributesFromQs = function getAttributesFromQs(qs) {
  return Object.keys(qs).filter(function (key) {
    return !["pageSize", "priceGte", "priceLte", "sortBy", "q"].includes(key);
  }).reduce(function (prev, curr) {
    prev[curr] = typeof qs[curr] === "string" ? [qs[curr]] : qs[curr];
    return prev;
  }, {});
};
export var getValueOrEmpty = function getValueOrEmpty(value) {
  return value === undefined || value === null ? "" : value;
};
export var convertSortByFromString = function convertSortByFromString(sortBy) {
  if (!sortBy) {
    return null;
  }

  var direction = sortBy.startsWith("-") ? OrderDirection.DESC : OrderDirection.ASC;
  var field;

  switch (sortBy.replace(/^-/, "")) {
    case "name":
      field = ProductOrderField.NAME;
      break;

    case "price":
      field = ProductOrderField.MINIMAL_PRICE;
      break;

    case "updated_at":
      field = ProductOrderField.DATE;
      break;

    default:
      return null;
  }

  return {
    field: field,
    direction: direction
  };
};
export var maybe = function maybe(exp, d) {
  try {
    var result = exp();
    return result === undefined ? d : result;
  } catch (_unused) {
    return d;
  }
};
export var parseQueryString = function parseQueryString(location) {
  var query = parseQs(window.location.search.substr(1));
  each(query, function (value, key) {
    if (Array.isArray(value)) {
      query = _objectSpread({}, query, _defineProperty({}, key, value[0]));
    }
  });
  return query;
};
export var updateQueryString = function updateQueryString(location, history) {
  var querystring = parseQueryString(location);
  return function (key, value) {
    if (value === "") {
      delete querystring[key];
    } else {
      querystring[key] = value || key;
    }

    history.replace("?".concat(stringifyQs(querystring)));
  };
};
export var findFormErrors = function findFormErrors(result) {
  if (result) {
    var data = Object.values(maybe(function () {
      return result.data;
    }));
    return data.reduce(function (prevVal, currVal) {
      var errors = currVal.errors || [];
      return [].concat(_toConsumableArray(prevVal), _toConsumableArray(errors));
    }, []);
  }

  return [];
};
export var removeEmptySpaces = function removeEmptySpaces(text) {
  return text.replace(/\s+/g, "");
};