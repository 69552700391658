import _taggedTemplateLiteral from "@babel/runtime/helpers/taggedTemplateLiteral";

function _templateObject3() {
  var data = _taggedTemplateLiteral(["\n  font-size: ", ";\n  color: ", ";\n  padding-right: 0.6rem;\n  margin: 0;\n"]);

  _templateObject3 = function _templateObject3() {
    return data;
  };

  return data;
}

function _templateObject2() {
  var data = _taggedTemplateLiteral(["\n  font-size: ", ";\n  font-weight: ", ";\n  color: ", ";\n  text-transform: uppercase;\n  padding-right: 0.6rem;\n  margin: 0;\n"]);

  _templateObject2 = function _templateObject2() {
    return data;
  };

  return data;
}

function _templateObject() {
  var data = _taggedTemplateLiteral(["\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n  padding: ", ";\n  ", "\n"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

import { styled } from "@styles";
export var Header = styled.div(_templateObject(), function (props) {
  return "1.1rem ".concat(props.theme.spacing.gutter);
}, function (_ref) {
  var divider = _ref.divider,
      theme = _ref.theme;
  return divider && "border-bottom: 1px solid ".concat(theme.colors.light, ";");
});
export var Title = styled.h4(_templateObject2(), function (_ref2) {
  var size = _ref2.size,
      typography = _ref2.theme.typography;
  return size === "lg" ? typography.h4FontSize : typography.baseFontSize;
}, function (props) {
  return props.theme.typography.boldFontWeight;
}, function (props) {
  return props.theme.colors.baseFont;
});
export var Paragraph = styled.p(_templateObject3(), function (props) {
  return props.theme.typography.smallFontSize;
}, function (props) {
  return props.theme.colors.lightFont;
});