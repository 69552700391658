import React from "react";
import * as S from "./styles";
export var TileGrid = function TileGrid(_ref) {
  var elements = _ref.elements,
      _ref$columns = _ref.columns,
      columns = _ref$columns === void 0 ? 3 : _ref$columns;
  return React.createElement(S.Wrapper, null, elements.map(function (element, index) {
    return React.createElement(S.Tile, {
      key: index,
      columns: columns
    }, element);
  }));
};