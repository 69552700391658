import _extends from "@babel/runtime/helpers/extends";
import _objectWithoutProperties from "@babel/runtime/helpers/objectWithoutProperties";
import { Formik } from "formik";
import React from "react";
import { CreditCardFormContent } from "./CreditCardFormContent";
var INITIAL_CARD_VALUES_STATE = {
  ccCsc: "",
  ccExp: "",
  ccNumber: ""
};
export var CreditCardForm = function CreditCardForm(_ref) {
  var handleSubmit = _ref.handleSubmit,
      props = _objectWithoutProperties(_ref, ["handleSubmit"]);

  return React.createElement(Formik, {
    initialValues: INITIAL_CARD_VALUES_STATE,
    onSubmit: function onSubmit(values, _ref2) {
      var setSubmitting = _ref2.setSubmitting;
      handleSubmit(values);
      setSubmitting(false);
    }
  }, function (_ref3) {
    var handleChange = _ref3.handleChange,
        handleSubmit = _ref3.handleSubmit,
        values = _ref3.values;
    return React.createElement(CreditCardFormContent, _extends({
      handleChange: handleChange,
      handleSubmit: handleSubmit,
      values: values
    }, props));
  });
};