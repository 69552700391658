import React from "react";
import { useServiceWorker } from "@hooks";
import { ServiceWorkerContext } from ".";
export var ServiceWorkerProvider = function ServiceWorkerProvider(_ref) {
  var children = _ref.children,
      timeout = _ref.timeout;
  var context = useServiceWorker({
    timeout: timeout
  });
  return React.createElement(ServiceWorkerContext.Provider, {
    value: context
  }, children);
};