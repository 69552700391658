import React from "react";
import { Route, Switch } from "react-router-dom";
import { NotFound } from "../components";
import { OrderDetails } from "./views";
export var baseUrl = "/my-account/";
export var userOrderDetailsUrl = "".concat(baseUrl, "order/:id/");
export var orderHistoryUrl = "".concat(baseUrl, "order/history/");

var Routes = function Routes() {
  return React.createElement(Switch, null, React.createElement(Route, {
    path: userOrderDetailsUrl,
    component: OrderDetails
  }), React.createElement(Route, {
    component: NotFound
  }));
};

export default Routes;