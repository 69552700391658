import * as React from "react";
import "./scss/index.scss";
import { Message } from "..";
export var NotificationTemplate = function NotificationTemplate(_ref) {
  var message = _ref.message,
      options = _ref.options,
      close = _ref.close;
  return React.createElement("div", {
    className: "notification"
  }, React.createElement(Message, {
    title: message.title,
    status: options.type,
    onClose: close
  }, message.content));
};
export default NotificationTemplate;