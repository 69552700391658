import _slicedToArray from "@babel/runtime/helpers/slicedToArray";
import React, { useEffect } from "react";
import { useProductVariantsAttributes, useProductVariantsAttributesValuesSelection } from "@hooks";
import { ProductVariantAttributeSelect } from "./ProductVariantAttributeSelect";
import * as S from "./styles";

var ProductVariantPicker = function ProductVariantPicker(_ref) {
  var _ref$productVariants = _ref.productVariants,
      productVariants = _ref$productVariants === void 0 ? [] : _ref$productVariants,
      _ref$queryAttributes = _ref.queryAttributes,
      queryAttributes = _ref$queryAttributes === void 0 ? {} : _ref$queryAttributes,
      onAttributeChangeHandler = _ref.onAttributeChangeHandler,
      onChange = _ref.onChange,
      _ref$selectSidebar = _ref.selectSidebar,
      selectSidebar = _ref$selectSidebar === void 0 ? false : _ref$selectSidebar,
      selectSidebarTarget = _ref.selectSidebarTarget;
  var productVariantsAttributes = useProductVariantsAttributes(productVariants);

  var _useProductVariantsAt = useProductVariantsAttributesValuesSelection(productVariantsAttributes),
      _useProductVariantsAt2 = _slicedToArray(_useProductVariantsAt, 2),
      productVariantsAttributesSelectedValues = _useProductVariantsAt2[0],
      selectProductVariantsAttributesValue = _useProductVariantsAt2[1];

  useEffect(function () {
    var selectedVariant = productVariants.find(function (productVariant) {
      return productVariant.attributes.every(function (productVariantAttribute) {
        var productVariantAttributeId = productVariantAttribute.attribute.id;

        if (productVariantAttribute.values[0] && productVariantsAttributesSelectedValues[productVariantAttributeId] && productVariantAttribute.values[0].id === productVariantsAttributesSelectedValues[productVariantAttributeId].id) {
          return true;
        }

        return false;
      });
    });

    if (onChange) {
      onChange(productVariantsAttributesSelectedValues, selectedVariant);
    }
  }, [productVariantsAttributesSelectedValues]);

  var onAttributeChange = function onAttributeChange(id, value, slug) {
    selectProductVariantsAttributesValue(id, value);
    onAttributeChangeHandler(slug, value);
  };

  return React.createElement(S.Wrapper, null, Object.keys(productVariantsAttributes).map(function (productVariantsAttributeId) {
    var productVariantsAttribute = productVariantsAttributes[productVariantsAttributeId];
    var slug = productVariantsAttribute.attribute.slug;
    return React.createElement(ProductVariantAttributeSelect, {
      key: productVariantsAttributeId,
      selectSidebar: selectSidebar,
      selectSidebarTarget: selectSidebarTarget,
      productVariants: productVariants,
      productVariantsAttributeId: productVariantsAttributeId,
      productVariantsAttribute: productVariantsAttribute,
      defaultValue: queryAttributes[productVariantsAttributeId],
      productVariantsAttributesSelectedValues: productVariantsAttributesSelectedValues,
      onChangeSelection: function onChangeSelection(optionValue) {
        return onAttributeChange(productVariantsAttributeId, optionValue, slug);
      },
      onClearSelection: function onClearSelection() {
        return onAttributeChange(productVariantsAttributeId, null, slug);
      }
    });
  }));
};

ProductVariantPicker.displayName = "ProductVariantPicker";
export default ProductVariantPicker;