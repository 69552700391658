import _taggedTemplateLiteral from "@babel/runtime/helpers/taggedTemplateLiteral";

function _templateObject() {
  var data = _taggedTemplateLiteral(["\n  ", "\n  query SearchProducts(\n    $query: String!\n    $attributes: [AttributeInput]\n    $pageSize: Int\n    $sortBy: ProductOrder\n    $after: String\n  ) {\n    products(\n      filter: { search: $query, attributes: $attributes }\n      first: $pageSize\n      sortBy: $sortBy\n      after: $after\n    ) {\n      totalCount\n      edges {\n        node {\n          ...ProductPricingField\n          id\n          name\n          thumbnail {\n            url\n            alt\n          }\n          thumbnail2x: thumbnail(size: 510) {\n            url\n          }\n          category {\n            id\n            name\n          }\n        }\n      }\n      pageInfo {\n        endCursor\n        hasNextPage\n      }\n    }\n    attributes(filter: { filterableInStorefront: true }, first: 100) {\n      edges {\n        node {\n          id\n          name\n          slug\n          values {\n            id\n            name\n            slug\n          }\n        }\n      }\n    }\n  }\n"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

import gql from "graphql-tag";
import { TypedQuery } from "../../core/queries";
import { productPricingFragment } from "../Product/queries";
export var searchProductsQuery = gql(_templateObject(), productPricingFragment);
export var TypedSearchProductsQuery = TypedQuery(searchProductsQuery);