import _slicedToArray from "@babel/runtime/helpers/slicedToArray";
import React from "react";
export var useNetworkStatus = function useNetworkStatus(callBack) {
  var _React$useState = React.useState("onLine" in navigator ? navigator.onLine : true),
      _React$useState2 = _slicedToArray(_React$useState, 2),
      online = _React$useState2[0],
      setOnline = _React$useState2[1];

  var updateOnlineStatus = function updateOnlineStatus() {
    var status = navigator.onLine;

    if (callBack) {
      callBack(status);
    }

    setOnline(navigator.onLine);
  };

  React.useEffect(function () {
    addEventListener("offline", updateOnlineStatus);
    addEventListener("online", updateOnlineStatus);
    return function () {
      removeEventListener("offline", updateOnlineStatus);
      removeEventListener("online", updateOnlineStatus);
    };
  }, []);
  return {
    online: online
  };
};