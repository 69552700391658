import _slicedToArray from "@babel/runtime/helpers/slicedToArray";
import React, { useState } from "react";
import { useHandlerWhenClickedOutside } from "@hooks";
import * as S from "./styles";
export var DropdownMenu = function DropdownMenu(_ref) {
  var header = _ref.header,
      items = _ref.items,
      type = _ref.type;

  var _useState = useState(false),
      _useState2 = _slicedToArray(_useState, 2),
      visible = _useState2[0],
      setVisible = _useState2[1];

  var _useHandlerWhenClicke = useHandlerWhenClickedOutside(function () {
    setVisible(false);
  }),
      setElementRef = _useHandlerWhenClicke.setElementRef;

  return React.createElement(S.Wrapper, {
    ref: setElementRef(),
    onMouseEnter: function onMouseEnter() {
      return type === "hoverable" && setVisible(true);
    },
    onMouseLeave: function onMouseLeave() {
      return type === "hoverable" && setVisible(false);
    },
    onClick: function onClick() {
      return type === "clickable" && setVisible(!visible);
    }
  }, header, visible && React.createElement(S.Content, null, React.createElement("ul", null, items.map(function (element, id) {
    return React.createElement("li", {
      key: id,
      onClick: function onClick() {
        setVisible(false);
        element.onClick();
      },
      "data-test": element.testingContext
    }, element.content);
  }))));
};