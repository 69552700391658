import React from "react";
import * as S from "./styles";
export var Address = function Address(_ref) {
  var firstName = _ref.firstName,
      lastName = _ref.lastName,
      companyName = _ref.companyName,
      streetAddress1 = _ref.streetAddress1,
      streetAddress2 = _ref.streetAddress2,
      city = _ref.city,
      postalCode = _ref.postalCode,
      countryArea = _ref.countryArea,
      country = _ref.country,
      phone = _ref.phone;
  return React.createElement("div", null, React.createElement(S.Name, null, "".concat(firstName, " ").concat(lastName)), companyName && React.createElement(React.Fragment, null, companyName, " ", React.createElement("br", null)), streetAddress1, React.createElement("br", null), streetAddress2 && React.createElement(React.Fragment, null, streetAddress2, " ", React.createElement("br", null)), postalCode && "".concat(postalCode, ","), " ", city, React.createElement("br", null), countryArea && React.createElement(React.Fragment, null, countryArea, ", "), country.country, React.createElement("br", null), phone && React.createElement(React.Fragment, null, "Phone number: ", phone, " ", React.createElement("br", null)));
};