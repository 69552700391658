import React from "react";
import { icons } from "./definitions";

var getPathColor = function getPathColor(color, index) {
  if (typeof color === "string") {
    return color;
  }

  return color[index] ? color[index] : "inherit";
};

export var Icon = function Icon(_ref) {
  var _ref$size = _ref.size,
      size = _ref$size === void 0 ? 32 : _ref$size,
      color = _ref.color,
      name = _ref.name;
  var icon = icons[name];
  return React.createElement("svg", {
    height: size,
    viewBox: "0 0 32 32",
    width: size
  }, icon && icon.map(function (path, index) {
    return React.createElement("path", {
      d: path.d,
      fill: color ? getPathColor(color, index) : path.fill,
      key: index
    });
  }));
};