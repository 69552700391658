import _taggedTemplateLiteral from "@babel/runtime/helpers/taggedTemplateLiteral";

function _templateObject4() {
  var data = _taggedTemplateLiteral(["\n  cursor: ", ";\n"]);

  _templateObject4 = function _templateObject4() {
    return data;
  };

  return data;
}

function _templateObject3() {
  var data = _taggedTemplateLiteral(["\n  background-color: ", ";\n  padding: ", ";\n"]);

  _templateObject3 = function _templateObject3() {
    return data;
  };

  return data;
}

function _templateObject2() {
  var data = _taggedTemplateLiteral(["\n  display: flex;\n  flex-direction: column;\n  height: ", ";\n  overflow: auto;\n"]);

  _templateObject2 = function _templateObject2() {
    return data;
  };

  return data;
}

function _templateObject() {
  var data = _taggedTemplateLiteral(["\n  display: flex;\n  flex-direction: column;\n  justify-content: space-between;\n  width: 100%;\n  box-shadow: 6px 0px 30px rgba(0, 0, 0, 0.15);\n"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

import { styled } from "@styles";
export var Wrapper = styled.div(_templateObject());
export var Content = styled.div(_templateObject2(), function (_ref) {
  var spacing = _ref.theme.spacing;
  return "calc(100vh - ".concat(spacing.gutter, ")");
});
export var Footer = styled.div(_templateObject3(), function (props) {
  return props.theme.colors.light;
}, function (_ref2) {
  var spacing = _ref2.theme.spacing;
  return "1.3rem ".concat(spacing.gutter, " 1rem ").concat(spacing.gutter);
});
export var Option = styled.div(_templateObject4(), function (props) {
  return props.disabled ? "default" : "pointer";
});