import _taggedTemplateLiteral from "@babel/runtime/helpers/taggedTemplateLiteral";

function _templateObject4() {
  var data = _taggedTemplateLiteral(["\n  padding: ", ";\n  vertical-align: middle;\n  cursor: pointer;\n  border-radius: 1rem;\n\n  > svg > path {\n    fill: ", ";\n  }\n\n  &:hover {\n    background-color: ", ";\n    > svg > path {\n      fill: ", ";\n    }\n  }\n\n  &:active {\n    background-color: ", ";\n  }\n\n  &:disabled {\n    background-color: ", ";\n\n    &,\n    &:hover {\n      cursor: default;\n    }\n  }\n"]);

  _templateObject4 = function _templateObject4() {
    return data;
  };

  return data;
}

function _templateObject3() {
  var data = _taggedTemplateLiteral(["\n  display: inline-block;\n  font-size: ", ";\n  font-weight: ", ";\n  line-height: ", ";\n  margin-right: ", ";\n  vertical-align: middle;\n"]);

  _templateObject3 = function _templateObject3() {
    return data;
  };

  return data;
}

function _templateObject2() {
  var data = _taggedTemplateLiteral(["\n  box-shadow: inset 0px 0px 0px 3px\n    ", ";\n  border-left: 1px solid ", ";\n  border-right: 1px solid ", ";\n"]);

  _templateObject2 = function _templateObject2() {
    return data;
  };

  return data;
}

function _templateObject() {
  var data = _taggedTemplateLiteral(["\n  background-color: ", ";\n  padding: ", ";\n  border: none;\n  transition: 0.3s;\n  outline: none;\n  border-radius: 2rem;\n  color: ", ";\n  width: ", "\n  display: inline-block;\n  cursor: default;\n"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

import { styled } from "@styles";
var padding = {
  md: "0.4rem 0.4rem 0.4rem 0.8rem",
  sm: "0.1rem"
};
var paddingCloseButton = {
  md: "0.4rem 0.4rem 0.3rem 0.4rem",
  sm: "0.1rem"
};

var fontSize = function fontSize(_fontSize, smallFontSize) {
  return {
    md: _fontSize,
    sm: smallFontSize
  };
};

export var Primary = styled.div(_templateObject(), function (props) {
  return props.theme.chip.colors[props.color].background;
}, function (props) {
  return padding[props.size];
}, function (props) {
  return props.theme.chip.colors[props.color].color;
}, function (props) {
  return props.fullWidth ? "100%" : "auto";
});
export var Secondary = styled(Primary)(_templateObject2(), function (props) {
  return props.theme.chip.colors.secondary.color;
}, function (props) {
  return props.theme.chip.colors.secondary.color;
}, function (props) {
  return props.theme.chip.colors.secondary.color;
});
export var Text = styled.span(_templateObject3(), function (_ref) {
  var size = _ref.size,
      typography = _ref.theme.chip.typography;
  return fontSize(typography.fontSize, typography.smallFontSize)[size];
}, function (props) {
  return props.theme.typography.boldFontWeight;
}, function (props) {
  return props.theme.typography.baseLineHeight;
}, function (props) {
  return paddingCloseButton[props.size];
});
export var CloseButton = styled.button(_templateObject4(), function (props) {
  return paddingCloseButton[props.size];
}, function (props) {
  return props.theme.chip.colors[props.color].color;
}, function (props) {
  return props.theme.chip.colors[props.color].hoverBackground;
}, function (props) {
  return props.theme.chip.colors[props.color].hoverColor;
}, function (props) {
  return props.theme.chip.colors[props.color].activeBackground;
}, function (props) {
  return props.theme.colors.disabled;
});