import _objectWithoutProperties from "@babel/runtime/helpers/objectWithoutProperties";
import React from "react";
import * as S from "./styles";
export var Tile = function Tile(_ref) {
  var header = _ref.header,
      children = _ref.children,
      footer = _ref.footer,
      props = _objectWithoutProperties(_ref, ["header", "children", "footer"]);

  return React.createElement(S.Wrapper, props, header && React.createElement(S.Header, null, React.createElement(S.Content, null, header)), React.createElement(S.Content, null, children), footer && React.createElement(S.Footer, null, footer));
};