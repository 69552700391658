import _objectSpread from "@babel/runtime/helpers/objectSpread";
import * as React from "react";
import { META_DEFAULTS } from "../../core/config";
import MetaConsumer from "./consumer";
import { Provider as MetaProvider } from "./context";

var removeEmpty = function removeEmpty(obj) {
  var newObj = {};
  Object.keys(obj).forEach(function (prop) {
    if (obj[prop] && obj[prop] !== "") {
      newObj[prop] = obj[prop];
    }
  });
  return newObj;
};

var MetaWrapper = function MetaWrapper(_ref) {
  var children = _ref.children,
      meta = _ref.meta;
  return React.createElement(MetaProvider, {
    value: _objectSpread({}, META_DEFAULTS, removeEmpty(meta))
  }, React.createElement(MetaConsumer, null, children));
};

export default MetaWrapper;