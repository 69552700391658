import _taggedTemplateLiteral from "@babel/runtime/helpers/taggedTemplateLiteral";

function _templateObject4() {
  var data = _taggedTemplateLiteral(["\n  display: flex;\n  justify-content: space-between;\n  padding-bottom: 1.5rem;\n  color: ", ";\n\n  svg {\n    transform: rotate(-90deg);\n  }\n"]);

  _templateObject4 = function _templateObject4() {
    return data;
  };

  return data;
}

function _templateObject3() {
  var data = _taggedTemplateLiteral(["\n  font-weight: ", ";\n  padding-bottom: 2rem;\n"]);

  _templateObject3 = function _templateObject3() {
    return data;
  };

  return data;
}

function _templateObject2() {
  var data = _taggedTemplateLiteral(["\n  position: absolute;\n  top: 0;\n  left: 0;\n  width: 100%;\n  padding: 1.25rem;\n  height: auto;\n  /* height: 350px; */\n  overflow: visible;\n  z-index: 10;\n  background-color: white;\n  box-shadow: 0 0 0 9999px rgba(50, 50, 50, 0.1);\n"]);

  _templateObject2 = function _templateObject2() {
    return data;
  };

  return data;
}

function _templateObject() {
  var data = _taggedTemplateLiteral(["\n  background-color: ", ";\n  padding: 1.25rem;\n  font-weight: ", ";\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n  position: relative;\n  margin-bottom: 2rem;\n"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

import { styled } from "@styles";
export var Wrapper = styled.div(_templateObject(), function (props) {
  return props.theme.colors.light;
}, function (props) {
  return props.theme.typography.boldFontWeight;
});
export var Overlay = styled.div(_templateObject2());
export var MenuHeader = styled.div(_templateObject3(), function (props) {
  return props.theme.typography.boldFontWeight;
});
export var MenuItem = styled.div(_templateObject4(), function (props) {
  return props.active ? props.theme.colors.activeMenuOption : "";
});