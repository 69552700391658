import _taggedTemplateLiteral from "@babel/runtime/helpers/taggedTemplateLiteral";

function _templateObject2() {
  var data = _taggedTemplateLiteral(["\n  path {\n    transition: 0.3s;\n  }\n\n  &:hover {\n    path {\n      fill: ", ";\n    }\n  }\n"]);

  _templateObject2 = function _templateObject2() {
    return data;
  };

  return data;
}

function _templateObject() {
  var data = _taggedTemplateLiteral(["\n  padding: ", ";\n"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

import { styled } from "@styles";
import { spacer } from "@styles/constants";
export var Wrapper = styled.div(_templateObject(), function (props) {
  return "".concat(props.theme.spacing.spacer, " ").concat(spacer / 2, "rem");
});
export var Link = styled.a(_templateObject2(), function (props) {
  return props.theme.colors.primary;
});