import _extends from "@babel/runtime/helpers/extends";
import _objectWithoutProperties from "@babel/runtime/helpers/objectWithoutProperties";
import React from "react";
import * as S from "./styles";

/**
 * Radio input.
 */
var Radio = function Radio(_ref) {
  var checked = _ref.checked,
      children = _ref.children,
      _ref$customLabel = _ref.customLabel,
      customLabel = _ref$customLabel === void 0 ? false : _ref$customLabel,
      props = _objectWithoutProperties(_ref, ["checked", "children", "customLabel"]);

  var StyledInput = customLabel ? S.Input : S.LabeledInput;
  return React.createElement(StyledInput, {
    checked: checked || false
  }, React.createElement("input", _extends({
    type: "radio",
    checked: checked
  }, props)), " ", React.createElement("div", null, React.createElement("span", null)), children);
};

export { Radio };