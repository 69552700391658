import ReactDOM from "react-dom";
export var getContentWindowHeight = function getContentWindowHeight() {
  var headerRef = document.getElementById("header");
  var footerRef = document.getElementById("footer");
  var headerHeight = headerRef ? headerRef.offsetHeight : 0;
  var footerHeight = footerRef ? footerRef.offsetHeight : 0;
  return window.innerHeight - headerHeight - footerHeight;
};
var DEFAULT_BACKGROUND_COLOR = "#fff";
/**
 * Get the element background color.
 * If no background color is provided then the default is rgba(0, 0, 0, 0)
 * in this case the default color to cover is white (#fff)
 * @param ref Reference to the element to check.
 */

export var getBackgroundColor = function getBackgroundColor(ref) {
  /* eslint-disable-next-line react/no-find-dom-node */
  var el = ReactDOM.findDOMNode(ref);

  if (el && el.parentElement) {
    if (el.nodeName === "BODY") {
      return DEFAULT_BACKGROUND_COLOR;
    }

    var bgColor = window.getComputedStyle(el.parentElement, null).backgroundColor;

    if (bgColor && bgColor !== "rgba(0, 0, 0, 0)") {
      return bgColor;
    }

    return getBackgroundColor(el.parentElement);
  }

  return DEFAULT_BACKGROUND_COLOR;
};