import * as React from "react";
import { Message } from "..";
export var NotificationOverlay = function NotificationOverlay(_ref) {
  var _ref$overlay = _ref.overlay,
      hide = _ref$overlay.hide,
      context = _ref$overlay.context;
  return React.createElement(Message, {
    title: context.title,
    status: context.status,
    onClose: hide
  }, context.content);
};
export default NotificationOverlay;