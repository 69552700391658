import _taggedTemplateLiteral from "@babel/runtime/helpers/taggedTemplateLiteral";

function _templateObject() {
  var data = _taggedTemplateLiteral(["\n  font-size: ", ";\n  font-weight: ", ";\n  color: ", ";\n  background: transparent;\n  outline: none;\n  border: none;\n  box-shadow: none;\n  transform: none;\n  text-decoration: none;\n  text-transform: uppercase;\n  padding: 0;\n  transition: color 0.3s ease;\n  &:hover {\n    color: ", ";\n  }\n"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

import { styled } from "@styles";
export var ButtonLink = styled.button(_templateObject(), function (_ref) {
  var size = _ref.size,
      typography = _ref.theme.typography;
  return size === "md" ? typography.baseFontSize : typography.smallFontSize;
}, function (props) {
  return props.theme.typography.boldFontWeight;
}, function (_ref2) {
  var color = _ref2.color,
      link = _ref2.theme.link;
  return color === "secondary" ? link.secondary.color : link.base.color;
}, function (_ref3) {
  var color = _ref3.color,
      link = _ref3.theme.link;
  return color === "secondary" ? link.secondary.hoverColor : link.base.hoverColor;
});