import _extends from "@babel/runtime/helpers/extends";
import _objectWithoutProperties from "@babel/runtime/helpers/objectWithoutProperties";
import React from "react";
import * as S from "./styles";
export var ButtonLink = function ButtonLink(_ref) {
  var children = _ref.children,
      _ref$color = _ref.color,
      color = _ref$color === void 0 ? "base" : _ref$color,
      testingContext = _ref.testingContext,
      testingContextId = _ref.testingContextId,
      _ref$size = _ref.size,
      size = _ref$size === void 0 ? "md" : _ref$size,
      props = _objectWithoutProperties(_ref, ["children", "color", "testingContext", "testingContextId", "size"]);

  return React.createElement(S.ButtonLink, _extends({
    "data-test": testingContext,
    "data-test-id": testingContextId,
    color: color,
    size: size
  }, props), children);
};