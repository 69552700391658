import _taggedTemplateLiteral from "@babel/runtime/helpers/taggedTemplateLiteral";

function _templateObject() {
  var data = _taggedTemplateLiteral(["\n  mutation RegisterAccount(\n    $email: String!\n    $password: String!\n    $redirectUrl: String!\n  ) {\n    accountRegister(\n      input: { email: $email, password: $password, redirectUrl: $redirectUrl }\n    ) {\n      errors {\n        field\n        message\n      }\n      requiresConfirmation\n    }\n  }\n"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

import gql from "graphql-tag";
import { TypedMutation } from "../../../core/mutations";
var accountRegisterMutation = gql(_templateObject());
export var TypedAccountRegisterMutation = TypedMutation(accountRegisterMutation);