import "./scss/index.scss";
import * as React from "react";
import ReactSVG from "react-svg";
import closeImg from "../../images/x.svg";

var Message = function Message(_ref) {
  var title = _ref.title,
      _ref$status = _ref.status,
      status = _ref$status === void 0 ? "neutral" : _ref$status,
      children = _ref.children,
      onClose = _ref.onClose;
  return React.createElement("div", {
    className: "message message__status-".concat(status)
  }, React.createElement("p", {
    className: "message__title"
  }, title), children ? React.createElement("div", {
    className: "message__content"
  }, children) : null, React.createElement(ReactSVG, {
    path: closeImg,
    className: "message__close-icon",
    onClick: onClose
  }));
};

export default Message;