import _extends from "@babel/runtime/helpers/extends";
import React from "react";
import { Button, ButtonLink } from "@components/atoms";
import * as S from "./styles";

var LoadingText = function LoadingText() {
  return React.createElement(React.Fragment, null, "Loading");
};

var getBtnAction = function getBtnAction(btn) {
  return btn.action && {
    onClick: btn.action
  };
};

var renderCancelBtn = function renderCancelBtn(cancelBtn) {
  return cancelBtn && React.createElement(ButtonLink, _extends({}, getBtnAction(cancelBtn), {
    testingContext: "cancelButton",
    type: "button",
    color: "secondary"
  }), cancelBtn.text);
};

var renderSubmitBtn = function renderSubmitBtn(submitBtn, disabled, formId) {
  return submitBtn && React.createElement(Button, _extends({
    testingContext: submitBtn.testingContext
  }, getBtnAction(submitBtn), {
    type: formId ? "submit" : "button",
    form: formId,
    disabled: disabled,
    size: "sm"
  }), disabled ? React.createElement(LoadingText, null) : submitBtn.text);
};

export var FormFooter = function FormFooter(_ref) {
  var cancelBtn = _ref.cancelBtn,
      _ref$disabled = _ref.disabled,
      disabled = _ref$disabled === void 0 ? false : _ref$disabled,
      _ref$divider = _ref.divider,
      divider = _ref$divider === void 0 ? false : _ref$divider,
      formId = _ref.formId,
      submitBtn = _ref.submitBtn;
  return React.createElement(S.Footer, {
    divider: divider
  }, renderCancelBtn(cancelBtn), renderSubmitBtn(submitBtn, disabled, formId));
};