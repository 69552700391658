import _extends from "@babel/runtime/helpers/extends";
import _objectWithoutProperties from "@babel/runtime/helpers/objectWithoutProperties";
import "./scss/index.scss";
import classNames from "classnames";
import * as React from "react";
import Select from "react-select"; // tslint:disable

var SelectField = function SelectField(_ref) {
  var _ref$label = _ref.label,
      label = _ref$label === void 0 ? "" : _ref$label,
      _ref$styleType = _ref.styleType,
      styleType = _ref$styleType === void 0 ? "white" : _ref$styleType,
      rest = _objectWithoutProperties(_ref, ["label", "styleType"]);

  return React.createElement("div", {
    className: classNames("react-select-wrapper", {
      "react-select-wrapper--grey": styleType === "grey"
    })
  }, label ? React.createElement("span", {
    className: "input__label"
  }, label) : null, React.createElement(Select, _extends({
    classNamePrefix: "react-select"
  }, rest)));
};

export default SelectField;