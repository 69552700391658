import "./scss/index.scss";
import * as React from "react";
import { Overlay } from "../..";

var Modal = function Modal(_ref) {
  var overlay = _ref.overlay,
      testingContext = _ref.testingContext;
  return React.createElement(Overlay, {
    testingContext: testingContext,
    context: overlay
  }, overlay.context.content);
};

export default Modal;