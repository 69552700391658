import _taggedTemplateLiteral from "@babel/runtime/helpers/taggedTemplateLiteral";

function _templateObject() {
  var data = _taggedTemplateLiteral(["\n  query Article($slug: String!) {\n    page(slug: $slug) {\n      contentJson\n      id\n      seoDescription\n      seoTitle\n      slug\n      title\n    }\n    shop {\n      homepageCollection {\n        id\n        backgroundImage {\n          url\n        }\n      }\n    }\n  }\n"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

import gql from "graphql-tag";
import { TypedQuery } from "../../core/queries";
var articleQuery = gql(_templateObject());
export var TypedArticleQuery = TypedQuery(articleQuery);