import _taggedTemplateLiteral from "@babel/runtime/helpers/taggedTemplateLiteral";

function _templateObject() {
  var data = _taggedTemplateLiteral(["\n  bottom: 0;\n  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.3);\n  filter: blur(6px);\n  height: 94%;\n  left: 3%;\n  position: absolute;\n  width: 94%;\n  z-index: -1;\n"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

import { styled } from "@styles";
export var ShadowBox = styled.div(_templateObject());