import _slicedToArray from "@babel/runtime/helpers/slicedToArray";
import { useEffect, useState } from "react";
import { useProductVariantsAttributes } from "./useProductVariantsAttributes";
export var useSelectableProductVariantsAttributeValues = function useSelectableProductVariantsAttributeValues(consideredProductVariantsAttributeId, productVariants, productVariantsAttributesSelectedValues) {
  var _useState = useState([]),
      _useState2 = _slicedToArray(_useState, 2),
      productPossibleVariants = _useState2[0],
      setProductPossibleVariants = _useState2[1];

  var productPossibleVariantsAttributes = useProductVariantsAttributes(productPossibleVariants);
  useEffect(function () {
    var possibleVariants = productVariants.filter(function (productVariant) {
      return Object.keys(productVariantsAttributesSelectedValues).every(function (selectedValueAttributeId) {
        if (selectedValueAttributeId === consideredProductVariantsAttributeId) {
          return true;
        }

        if (!productVariantsAttributesSelectedValues[selectedValueAttributeId]) {
          return true;
        }

        return productVariant.attributes.some(function (productVariantAttribute) {
          return productVariantAttribute.attribute.id === selectedValueAttributeId && productVariantAttribute.values[0] === productVariantsAttributesSelectedValues[selectedValueAttributeId];
        });
      });
    });
    setProductPossibleVariants(possibleVariants);
  }, [consideredProductVariantsAttributeId, productVariants, productVariantsAttributesSelectedValues]);
  return productPossibleVariantsAttributes;
};