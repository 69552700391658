import * as braintree from "braintree-web";
export var braintreePayment = function braintreePayment(paymentClientToken, creditCard) {
  return new Promise(function (resolve, reject) {
    braintree.client.create({
      authorization: paymentClientToken
    }, function (_err, client) {
      client.request({
        data: {
          creditCard: creditCard
        },
        endpoint: "payment_methods/credit_cards",
        method: "post"
      }, function (error, response) {
        if (error) {
          if (error.details.originalError.fieldErrors.length > 0) {
            error.details.originalError.fieldErrors.map(function (error) {
              if (error.field === "creditCard") {
                reject(error.fieldErrors);
              }
            });
          }
        } else {
          var lastDigits = response.creditCards[0].details.lastFour;
          var ccType = response.creditCards[0].details.cardType;
          var token = response.creditCards[0].nonce;
          resolve({
            lastDigits: lastDigits,
            ccType: ccType,
            token: token
          });
        }
      });
    });
  });
};