import _slicedToArray from "@babel/runtime/helpers/slicedToArray";

/* eslint-disable no-console */
import { useState } from "react";
export var useLocalStorage = function useLocalStorage(key, initialValue) {
  var _useState = useState(function () {
    try {
      var item = window.localStorage.getItem(key);
      return item ? JSON.parse(item) : initialValue;
    } catch (error) {
      console.error(error);
      return initialValue;
    }
  }),
      _useState2 = _slicedToArray(_useState, 2),
      storedValue = _useState2[0],
      setStoredValue = _useState2[1];

  var setValue = function setValue(value) {
    try {
      var valueToStore = value instanceof Function ? value(storedValue) : value;
      setStoredValue(valueToStore);
      window.localStorage.setItem(key, JSON.stringify(valueToStore));
    } catch (error) {
      console.error(error);
    }
  };

  return {
    storedValue: storedValue,
    setValue: setValue
  };
};