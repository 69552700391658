import _taggedTemplateLiteral from "@babel/runtime/helpers/taggedTemplateLiteral";

function _templateObject3() {
  var data = _taggedTemplateLiteral(["\n  > div {\n    display: inline-block;\n    padding: 0;\n    margin: 0;\n    margin-right: 0.6rem;\n  }\n"]);

  _templateObject3 = function _templateObject3() {
    return data;
  };

  return data;
}

function _templateObject2() {
  var data = _taggedTemplateLiteral(["\n  margin-top: 0.5rem;\n  margin-bottom: ", ";\n"]);

  _templateObject2 = function _templateObject2() {
    return data;
  };

  return data;
}

function _templateObject() {
  var data = _taggedTemplateLiteral(["\n  font-weight: ", ";\n"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

import { styled } from "@styles";
export var BoldTitle = styled.div(_templateObject(), function (props) {
  return props.theme.typography.boldFontWeight;
});
export var TextContent = styled.div(_templateObject2(), function (props) {
  return props.theme.spacing.spacer;
});
export var FooterContent = styled.div(_templateObject3());