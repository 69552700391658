import _taggedTemplateLiteral from "@babel/runtime/helpers/taggedTemplateLiteral";

function _templateObject4() {
  var data = _taggedTemplateLiteral(["\n  display: flex;\n  position: fixed;\n  overflow-y: auto;\n  width: 100%;\n  height: 100%;\n  min-height: 100vh;\n  top: 0;\n  z-index: 2;\n  transition: opacity 0.2s ease;\n  transition-delay: ", ";\n  background-color: ", ";\n  align-items: center;\n  justify-content: ", ";\n  opacity: ", ";\n"]);

  _templateObject4 = function _templateObject4() {
    return data;
  };

  return data;
}

function _templateObject3() {
  var data = _taggedTemplateLiteral(["\n        ", ": 0;\n        transform: translateX(", ");\n        animation: ", " 0.4s both;\n        animation-delay: ", ";\n      "]);

  _templateObject3 = function _templateObject3() {
    return data;
  };

  return data;
}

function _templateObject2() {
  var data = _taggedTemplateLiteral(["\n  display: flex;\n  position: relative;\n  width: ", ";\n  min-height: ", "px;\n  height: ", ";\n  background-color: ", ";\n  ", "\n"]);

  _templateObject2 = function _templateObject2() {
    return data;
  };

  return data;
}

function _templateObject() {
  var data = _taggedTemplateLiteral(["\n    from {\n      transform: translateX(", ");\n    }\n    to {\n      transform: translateX(", ");\n    }"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

import { styled } from "@styles";
import { css, keyframes } from "styled-components";

var getTranslate = function getTranslate(side) {
  return side === "left" ? "-100%" : "100%";
};

var slideAnimation = function slideAnimation(open, side) {
  var initialValue = open ? getTranslate(side) : 0;
  var endValue = open ? 0 : getTranslate(side);
  return keyframes(_templateObject(), initialValue, endValue);
};

var opacity = {
  entered: 1,
  entering: 0,
  exited: 0,
  exiting: 0,
  unmounted: 0
};
var justify = {
  center: "center",
  left: "flex-start",
  right: "flex-end"
};
var lightboxWidth = {
  center: "auto",
  left: "100%",
  right: "100%"
};

var lightboxHeight = function lightboxHeight(width) {
  return {
    center: "".concat(width, "px"),
    left: "auto",
    right: "auto"
  };
};

export var Lightbox = styled.div(_templateObject2(), function (_ref) {
  var position = _ref.position,
      modal = _ref.theme.modal;
  return lightboxHeight(modal.modalWidth)[position];
}, function (props) {
  return props.theme.modal.modalMinHeight;
}, function (_ref2) {
  var position = _ref2.position;
  return lightboxWidth[position];
}, function (props) {
  return props.theme.colors.white;
}, function (_ref3) {
  var open = _ref3.open,
      position = _ref3.position;

  if (position === "left" || position === "right") {
    return css(_templateObject3(), position, getTranslate(position), slideAnimation(open, position), open ? ".1s" : 0);
  }
});
Lightbox.displayName = "S.Lightbox";
export var Overlay = styled.div(_templateObject4(), function (_ref4) {
  var open = _ref4.open;
  return open ? 0 : ".4s";
}, function (_ref5) {
  var transparent = _ref5.transparent,
      theme = _ref5.theme;
  return transparent ? "" : theme.colors.overlay;
}, function (_ref6) {
  var position = _ref6.position;
  return justify[position];
}, function (_ref7) {
  var state = _ref7.state;
  return opacity[state];
});
Overlay.displayName = "S.Overlay";