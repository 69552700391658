import _toConsumableArray from "@babel/runtime/helpers/toConsumableArray";
import * as React from "react";
import { Helmet } from "react-helmet";
import { Consumer as MetaConsumer } from "./context";

var Consumer = function Consumer(_ref) {
  var children = _ref.children;
  return React.createElement(MetaConsumer, null, function (_ref2) {
    var title = _ref2.title,
        description = _ref2.description,
        image = _ref2.image,
        type = _ref2.type,
        url = _ref2.url,
        custom = _ref2.custom;
    return React.createElement(React.Fragment, null, React.createElement(Helmet, {
      title: title,
      meta: [{
        name: "description",
        content: description
      }, {
        property: "og:url",
        content: url
      }, {
        property: "og:title",
        content: title
      }, {
        property: "og:description",
        content: description
      }, {
        property: "og:type",
        content: type
      }, {
        property: "og:image",
        content: image
      }].concat(_toConsumableArray(custom))
    }), children);
  });
};

export default Consumer;