import _taggedTemplateLiteral from "@babel/runtime/helpers/taggedTemplateLiteral";

function _templateObject5() {
  var data = _taggedTemplateLiteral(["\n  text-transform: capitalize;\n  padding-bottom: 20px;\n  ", "\n"]);

  _templateObject5 = function _templateObject5() {
    return data;
  };

  return data;
}

function _templateObject4() {
  var data = _taggedTemplateLiteral(["\n  display: flex;\n  flex-direction: column;\n  padding: 20px 40px;\n"]);

  _templateObject4 = function _templateObject4() {
    return data;
  };

  return data;
}

function _templateObject3() {
  var data = _taggedTemplateLiteral(["\n  display: flex;\n  justify-content: flex-end;\n  width: 20%;\n"]);

  _templateObject3 = function _templateObject3() {
    return data;
  };

  return data;
}

function _templateObject2() {
  var data = _taggedTemplateLiteral(["\n  display: flex;\n  flex-wrap: wrap;\n  width: 80%;\n"]);

  _templateObject2 = function _templateObject2() {
    return data;
  };

  return data;
}

function _templateObject() {
  var data = _taggedTemplateLiteral(["\n  display: flex;\n  width: 100%;\n  background-color: ", ";\n  position: relative;\n  padding: 20px 40px;\n"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

import { NavLink } from "@components/atoms";
import { styled } from "@styles";
export var Wrapper = styled.div(_templateObject(), function (_ref) {
  var theme = _ref.theme;
  return theme.colors.white;
});
export var Rows = styled.div(_templateObject2());
export var Side = styled.div(_templateObject3());
export var RowItem = styled.div(_templateObject4());
export var SubLink = styled(NavLink)(_templateObject5(), function (_ref2) {
  var light = _ref2.light,
      theme = _ref2.theme;
  return light && "\n    color: ".concat(theme.colors.lightFont, ";\n    font-size: ").concat(theme.typography.smallFontSize, ";\n  ");
});