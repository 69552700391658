import * as React from "react";
import { NetworkStatus } from ".";

var Online = function Online(_ref) {
  var children = _ref.children;
  return React.createElement(NetworkStatus, null, function (online) {
    return online ? children : null;
  });
};

export default Online;