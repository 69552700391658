import _objectSpread from "@babel/runtime/helpers/objectSpread";
import _slicedToArray from "@babel/runtime/helpers/slicedToArray";
import React from "react";
import Media from "react-responsive";
import { Transition } from "react-transition-group";
import { Icon } from "@components/atoms";
import { largeScreen } from "@styles/constants";
import LogoSmall from "images/logo-small.svg";
import { Overlay } from "..";
import * as S from "./styles";

var TopBar = function TopBar(_ref) {
  var children = _ref.children,
      onHide = _ref.onHide;
  return React.createElement(S.Bar, null, children, React.createElement(S.CloseIconWrapper, {
    onClick: onHide
  }, React.createElement(Icon, {
    name: "horizontal_line",
    size: 22
  })));
};

var MenuItem = function MenuItem(_ref2) {
  var name = _ref2.name,
      onClick = _ref2.onClick;
  return React.createElement(S.Item, null, React.createElement(S.NavButton, {
    onClick: onClick
  }, React.createElement("span", null, name), React.createElement(S.SubcategoryIcon, null, React.createElement(Icon, {
    name: "subcategories",
    size: 10
  }))));
}; // Animation settings


var duration = 250;
var defaultStyle = {
  left: "100%",
  transition: "left ".concat(duration, "ms ease-in-out")
};
var transitionStyles = {
  entered: {
    left: 0
  },
  entering: {
    left: "100%"
  },
  exited: {
    left: "100%"
  },
  exiting: {
    left: "100%"
  },
  unmounted: {
    left: "100%"
  }
};
export var SideNavbar = function SideNavbar(_ref3) {
  var show = _ref3.show,
      onHide = _ref3.onHide,
      items = _ref3.items,
      target = _ref3.target;

  var _React$useState = React.useState({
    buffer: {
      index: null,
      depth: null
    },
    depth: null,
    index: null
  }),
      _React$useState2 = _slicedToArray(_React$useState, 2),
      view = _React$useState2[0],
      _setView = _React$useState2[1];

  var setView = React.useCallback(function (state) {
    _setView(function (view) {
      return _objectSpread({}, view, state, {
        buffer: _objectSpread({}, view.buffer, state)
      });
    });
  }, []);

  var handleHide = function handleHide() {
    return onHide(false);
  };

  return React.createElement(Overlay, {
    position: "left",
    show: show,
    hide: handleHide,
    target: target,
    testingContext: "navigationMenu"
  }, React.createElement(S.Wrapper, null, React.createElement(S.Menu, null, React.createElement(TopBar, {
    onHide: handleHide
  }, React.createElement(S.LogoWrapper, {
    path: LogoSmall
  })), React.createElement(S.Link, {
    to: "/"
  }, "Home"), items.map(function (item, index) {
    return item.children.length > 0 ? React.createElement(MenuItem, {
      key: index,
      onClick: function onClick() {
        setView({
          index: index
        });
      },
      name: item.name
    }) : React.createElement(S.NavLink, {
      fullWidth: true,
      type: "side",
      item: item
    });
  }), React.createElement(Media, {
    maxWidth: largeScreen
  }, React.createElement(S.Item, null, React.createElement(S.Link, {
    to: "/wishlist"
  }, React.createElement(S.IconWrapper, null, React.createElement(Icon, {
    name: "heart",
    size: 24
  })), React.createElement("span", null, "my wishlist"))), React.createElement(S.Item, null, React.createElement(S.Link, {
    to: "/account"
  }, React.createElement(S.IconWrapper, null, React.createElement(Icon, {
    name: "profile",
    size: 24
  })), React.createElement("span", null, "my profile"))), React.createElement(S.Item, null, React.createElement(S.Link, {
    to: "/"
  }, "english")))), React.createElement(Transition, {
    in: view.buffer.index !== null // https://github.com/reactjs/react-transition-group/issues/284
    ,
    timeout: {
      enter: 0,
      exit: duration
    },
    onExited: function onExited() {
      return setView({
        index: null,
        depth: null
      });
    },
    unmountOnExit: true
  }, function (state) {
    return React.createElement(S.Menu, {
      style: _objectSpread({}, defaultStyle, transitionStyles[state])
    }, React.createElement(TopBar, {
      onHide: handleHide
    }, React.createElement(S.BackButton, {
      onClick: function onClick() {
        _setView(function (view) {
          return _objectSpread({}, view, {
            buffer: {
              depth: null,
              index: null
            }
          });
        });
      }
    }, React.createElement(S.IconWrapper, null, React.createElement(Icon, {
      name: "arrow_back",
      size: 22
    })), React.createElement("span", null, items[view.index].name))), items[view.index].children.map(function (item, depth) {
      return item.children.length > 0 ? React.createElement(MenuItem, {
        key: depth,
        onClick: function onClick() {
          setView({
            depth: depth
          });
        },
        name: item.name
      }) : React.createElement(S.NavLink, {
        fullWidth: true,
        type: "side",
        item: item
      });
    }));
  }), React.createElement(Transition, {
    in: view.buffer.index !== null && view.buffer.depth !== null // https://github.com/reactjs/react-transition-group/issues/284
    ,
    timeout: {
      enter: 0,
      exit: duration
    },
    onExited: function onExited() {
      return setView({
        depth: null
      });
    },
    unmountOnExit: true
  }, function (state) {
    return React.createElement(S.Menu, {
      style: _objectSpread({}, defaultStyle, transitionStyles[state])
    }, React.createElement(TopBar, {
      onHide: handleHide
    }, React.createElement(S.BackButton, {
      onClick: function onClick() {
        _setView(function (view) {
          return _objectSpread({}, view, {
            buffer: _objectSpread({}, view.buffer, {
              depth: null
            })
          });
        });
      }
    }, React.createElement(S.IconWrapper, null, React.createElement(Icon, {
      name: "arrow_back",
      size: 22
    })), React.createElement("span", null, items[view.index].children[view.depth].name))), items[view.index].children[view.depth].children.map(function (item, i) {
      return React.createElement(S.NavLink, {
        key: i,
        fullWidth: true,
        type: "side",
        item: item
      });
    }));
  })));
};